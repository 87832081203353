import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalRoot: {
			width: '100%',
			// height: '100%',
			overflow: 'auto',
			display: 'flex',
		},
		modalContainer: {
			margin: 'auto',
			padding: '40px',
			boxShadow: '0px 17px 27px #9D636329 !important',
			borderRadius: '22px !important',
			width: '817px',
			height: 'auto',
			[theme.breakpoints.down('md')]: {
				margin: 'auto',
				padding: '23px',
				minWidth: '383px',
				width: '92vw',
				height: 'auto',
			},
			[theme.breakpoints.down('sm')]: {
				margin: 'auto',
				padding: '27.5px',
				width: '92vw !important',
				height: 'auto',
				minWidth: 'auto',
			},
		},
		contentContainer: {
			padding: '43px',
			justifyContent: 'center',
			alignItems: 'center',
			[theme.breakpoints.down('sm')]: {
				padding: '0px',
			},
		},
		infoIcon: {
			color: "#0126C3"
		},
		header: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		image: {
			width: '175px !important',
			height: '175px',
			margin: '34px auto',
			[theme.breakpoints.down('md')]: {
				margin: '0px auto',
			},
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: '37px',
		},
		button: {
			fontFamily: 'Inter !important',
			width: '431px',
			height: '54px',
			borderRadius: '14px !important',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				fontSize: '13px !important',
			},
		}
	})
);
