import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext, FieldArray } from 'formik';
import Dropdown from '../form/Dropdown/Dropdown';
import { FiltersContainer, queryButtonStyles, checkboxStyles, Label, Icon, resetButtonStyles, Search, modalContainerStyles } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Checkbox from '../form/Checkbox/Checkbox';
import Button from '../Button';
import { categoriesAPI } from '../../api/modules/categories';
import { REPUBLIC_STATES } from '../../utils/republicStates';
import { SORT_OPTIONS } from '../../utils/sortOptions';
import {
  convertUrlParamsToFormValues,
  convertArrayToOptions,
  setFormValues,
  convertValuesToQueryParams,
  convertValuesToApiQuery,
  handleGeolocation,
  convertStatesArrayToOptions,
  getValuesByTypeInArray,
  getFirstValueFromParams,
  sortAlphabetically
} from '../../utils/searchFunctions';
import SearchBanner from '../SearchBanner/SearchBanner';
import SearchPills from './SearchPills/SearchPills';
import { useSearchParams } from 'react-router-dom';
import Modal from '../Modal/Modal';

const FormObserver = ({ setIsLoading, formik, fetchFilters, setShowGeolocationModal }: any) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { values, setFieldValue, isSubmitting }: any = useFormikContext();
  const [nearData, setNearData] = useState<any>({ id: 'null' });
  const didMount = useRef(false);
  const { user } = useSelector(
    (state: RootState) => state.session
  );

  useEffect(() => {
    // if (isSubmitting || values.filters) {
    fetchFilters(values);
    // };
  }, [searchParams]);

  // If searchParams, set form values
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setFormValues(convertUrlParamsToFormValues(params), setFieldValue);
  }, [window.location.search]);

  // If form values change, set searchParams
  useEffect(() => {
    const urlQuery = window.location.search;
    // Get satate filters
    const stateFilters = getValuesByTypeInArray('states', values?.filters);
    // const formQuery = convertValuesToQueryParams(values?.filters);



    // Uncheck near if there are no states selected
    if (values.near.length && !stateFilters.length && isSubmitting) {
      formik.setFieldValue('near', []);
    }


    // If toogle changes
    if (didMount.current) {
      // if (urlQuery !== formQuery) {
      // If isSubmitting is true
      if (isSubmitting) {
        navigate(convertValuesToQueryParams(values?.filters));
      }
      // }
    } else {
      didMount.current = true;
    }


  }, [values, isSubmitting]);

  useEffect(() => {
    const statesParams = new URLSearchParams(window.location.search).get('states')?.split(',');

    // If "near" value changes
    if (
      values.near.length &&
      !statesParams?.includes(nearData?.id)
    ) {
      setIsLoading(true);
      handleGeolocation(user).then((currentState) => {
        if (currentState?.id) {
          const data = JSON.stringify({ type: 'states', value: currentState?.id });
          formik.setFieldValue('filters', [data]);
          setNearData(currentState);
          navigate(convertValuesToQueryParams([data]));
          return;
        }

        // If geolocation is not available
        setShowGeolocationModal(true);
        setFieldValue('near', []);
        setNearData({ id: 'null' });
      }).catch((error) => {
        setNearData({ id: 'null' });
        console.log(error);
      }
      ).finally(() => {
        setIsLoading(false);
      });
    }

    // If "near" has been unchecked
    if (!values.near.length && nearData?.id !== 'null' && nearData && values.filters.length && values.filters.includes(JSON.stringify({ type: 'states', value: nearData?.id }))) {
      formik.setFieldValue('filters', []);
      navigate(convertValuesToQueryParams([]));
    }

  }, [values]);


  return null;
};


const SearchFilters = ({ isLoading, searchParams, setSearchParams, result, noResults, setIsLoading, ...props }: any) => {
  const { user } = useSelector((state: RootState) => state.session);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [categories, setCategories] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showGeolocationModal, setShowGeolocationModal] = useState<boolean>(false);

  const urlQuery = new URLSearchParams(window.location.search);
  const initialValues = {
    categories: [],
    brands: [],
    states: [],
    near: [],
    order: '',
    query: '',
    filters: []
  };

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const _fetchFilters = async (values?: any) => {
    setIsLoadingFilters(true);
    const valuesFromParams = convertUrlParamsToFormValues(urlQuery);
    const brandsValues = getValuesByTypeInArray('brands', valuesFromParams?.filters);
    const categoriesValues = getValuesByTypeInArray('categories', valuesFromParams?.filters);
    const statesValues = getValuesByTypeInArray('states', valuesFromParams?.filters);
    const filterControl = await getFirstValueFromParams();

    let categoriesQuery = (filterControl && filterControl === 'categories') ? 'search-categories' : 'search-categories' + convertValuesToApiQuery({ brands: brandsValues, states: statesValues });
    let brandsQuery = (filterControl && filterControl === 'brands') ? 'search-brands' : 'search-brands' + convertValuesToApiQuery({ categories: categoriesValues, states: statesValues });
    let statesQuery = (filterControl && filterControl === 'states') ? 'search-states' : 'search-states' + convertValuesToApiQuery({ categories: categoriesValues, brands: brandsValues });

    const resCategories = await categoriesAPI.categoriesFilter(categoriesQuery);
    const resBrands = await categoriesAPI.categoriesFilter(brandsQuery);
    const resStates = await categoriesAPI.categoriesFilter(statesQuery);

    Promise.all([resCategories, resBrands, resStates]).then((response) => {
      setCategories(sortAlphabetically(convertArrayToOptions(response[0]?.data, 'categories')));
      setBrands(sortAlphabetically(convertArrayToOptions(response[1]?.data, 'brands')));
      setStates(convertStatesArrayToOptions(response[2]?.data, 'states'));
    }).finally(() => {
      setIsLoadingFilters(false);
    });
  }

  // Get filters from endpoints
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   _fetchFilters();
  // }, [searchParams])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
        }}
        validateOnChange={true}
        enableReinitialize
      >
        {(formik) => {

          return (
            <Search open={open}>
              <Form>
                <FormObserver setIsLoading={setIsLoading} formik={formik} fetchFilters={_fetchFilters} setShowGeolocationModal={setShowGeolocationModal} />

                <SearchBanner
                  query={urlQuery.get('query')}
                  categories={categories}
                  brands={brands}
                  values={formik?.values}
                  data={result}
                  noResults={noResults}
                />

                {isMobile && (
                  <div className='col-12 px-3'>
                    <Button variant="outline" type="button" onClick={() => setOpen(!open)} className='w-100'>Filtros</Button>
                  </div>
                )}

                <FiltersContainer open={open}>
                  {urlQuery.get('query') && (
                    <FieldArray name="filters">
                      {(arrayHelpers) => (
                        <Button
                          variant="solid"
                          type="button"
                          onClick={() => {
                            // contains the index of the element in the array that satisfies the provided testing function
                            const indexInArray = formik.values.filters.findIndex((element: any) => {
                              const option = JSON.parse(element);
                              return option.type === 'query';
                            });

                            if (indexInArray !== -1) {
                              arrayHelpers.remove(indexInArray - 1);
                              formik.setSubmitting(true);
                              formik.submitForm();
                            }

                          }}
                          style={queryButtonStyles}
                        >
                          "<Label>{urlQuery.get('query')}</Label>"
                          <Icon src={process.env.PUBLIC_URL + "/assets/icons/cross.svg"} alt="close" />
                        </Button>
                      )}
                    </FieldArray>
                  )}
                  <Dropdown options={categories} name="filters" type="categories">Categoría</Dropdown>
                  <Dropdown options={brands} name="filters" type="brands">Marca</Dropdown>
                  <Dropdown options={states} name="filters" type="states">Estado</Dropdown>

                  {user !== null && (
                    <Checkbox
                      id="near"
                      name="near"
                      style={checkboxStyles}
                      inputStyles={checkboxStyles.input}
                      value={JSON.stringify({ value: "checked", type: 'near' })}
                      disabled={isLoading || isLoadingFilters}>
                      Cerca de mí
                    </Checkbox>
                  )}

                  {urlQuery.toString().length > 0 && (
                    <Button
                      variant="squareOutline"
                      type="submit"
                      onClick={() => formik.resetForm()}
                      style={resetButtonStyles}
                      disabled={isLoading || isLoadingFilters}
                    >
                      <i className="bi bi-trash"></i>
                      Borrar filtros
                    </Button>
                  )}

                  <div className='ms-auto mr-0'>
                    <Dropdown options={SORT_OPTIONS} name="filters" type="order">Ordenar</Dropdown>
                  </div>

                  {isMobile && (
                    <div className='actions d-flex flex-column mt-auto mb-0 gap-3'>
                      <Button variant="solid" type="submit" onClick={() => setOpen(!open)}>Aplicar</Button>
                    </div>
                  )}
                </FiltersContainer>

                <SearchPills isLoading={isLoading || isLoadingFilters} catalogue={{ categories: categories, brands: brands, states: REPUBLIC_STATES }} />

              </Form>
            </Search>
          )
        }}
      </Formik>

      {showGeolocationModal && (
        <Modal
          show={showGeolocationModal}
          setShow={setShowGeolocationModal}
          showClose={false}
          className='modal-lg'
          container={{ style: modalContainerStyles }}
        >
          <div className='mb-2'>
            <p><strong>Habilita el permiso de ubicación </strong>para poder buscar los Benevits cerca de ti.</p>
          </div>

          {/* Is chrome*/}
          {(navigator.userAgent.toLowerCase().indexOf('firefox') <= -1 && navigator.userAgent.toLowerCase().indexOf('chrome') > -1) && (
            <div className='actions d-flex flex-column mb-0 gap-3'>
              <ol>
                <li className='text-left mb-4'>
                  <span>Da click en el icono </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={22} height={22} display="inline-block" style={{ float: 'initial' }}><path d="M5 7C5 6.17157 5.67157 5.5 6.5 5.5C7.32843 5.5 8 6.17157 8 7C8 7.82843 7.32843 8.5 6.5 8.5C5.67157 8.5 5 7.82843 5 7ZM6.5 3.5C4.567 3.5 3 5.067 3 7C3 8.933 4.567 10.5 6.5 10.5C8.433 10.5 10 8.933 10 7C10 5.067 8.433 3.5 6.5 3.5ZM12 8H20V6H12V8ZM16 17C16 16.1716 16.6716 15.5 17.5 15.5C18.3284 15.5 19 16.1716 19 17C19 17.8284 18.3284 18.5 17.5 18.5C16.6716 18.5 16 17.8284 16 17ZM17.5 13.5C15.567 13.5 14 15.067 14 17C14 18.933 15.567 20.5 17.5 20.5C19.433 20.5 21 18.933 21 17C21 15.067 19.433 13.5 17.5 13.5ZM4 16V18H12V16H4Z"></path></svg>
                  <span> de la barra de navegación y selecciona “Configuración de sitios”</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Se abrirá una pestaña nueva y en la opción “Ubicación” cambia la configuración a “Permitir”</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Regresa a la pestaña de Socio Infonavit y da clic en el ícono </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={22} height={22} display="inline-block" style={{ float: 'initial' }}><path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 9.25022 5.38734 6.82447 7.50024 5.38451L7.5 8H9.5V2L3.5 2V4L5.99918 3.99989C3.57075 5.82434 2 8.72873 2 12Z"></path></svg>
                  <span> para actualizar la página</span>
                </li>
              </ol>
            </div>
          )}

          {/* Is firefox  */}
          {(navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && navigator.userAgent.toLowerCase().indexOf('chrome') <= -1) && (
            <div className='actions d-flex flex-column mb-0 gap-3'>
              <ol>
                <li className='text-left mb-4'>
                  <span>Da click en el icono </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={22} height={22} display="inline-block" style={{ float: 'initial' }}><path d="M5 7C5 6.17157 5.67157 5.5 6.5 5.5C7.32843 5.5 8 6.17157 8 7C8 7.82843 7.32843 8.5 6.5 8.5C5.67157 8.5 5 7.82843 5 7ZM6.5 3.5C4.567 3.5 3 5.067 3 7C3 8.933 4.567 10.5 6.5 10.5C8.433 10.5 10 8.933 10 7C10 5.067 8.433 3.5 6.5 3.5ZM12 8H20V6H12V8ZM16 17C16 16.1716 16.6716 15.5 17.5 15.5C18.3284 15.5 19 16.1716 19 17C19 17.8284 18.3284 18.5 17.5 18.5C16.6716 18.5 16 17.8284 16 17ZM17.5 13.5C15.567 13.5 14 15.067 14 17C14 18.933 15.567 20.5 17.5 20.5C19.433 20.5 21 18.933 21 17C21 15.067 19.433 13.5 17.5 13.5ZM4 16V18H12V16H4Z"></path></svg>
                  <span> de la barra de navegación.</span>
                </li>

                <li className='text-left mb-4'>
                  <span>En la opción "Acceder a su ubicación" selecciona "Bloqueado temporalmente”</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Vuelve a seleccionar la opción "Cerca de mí".</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Se abrirá una notificación nueva y da clic en la opción “Permitir”</span>
                </li>
              </ol>
            </div>
          )}

          {/* Is firefox  */}
          {(navigator.userAgent.toLowerCase().indexOf('safari') > -1 && navigator.userAgent.toLowerCase().indexOf('chrome') <= -1 && navigator.userAgent.toLowerCase().indexOf('firefox') <= -1) && (
            <div className='actions d-flex flex-column mb-0 gap-3'>
              <ol>
                <li className='text-left mb-4'>
                  <span>{`Accede a la opción safari > Ajustes > Sitios > Ubicación`}</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Selecciona el sitio "Socio Infonavit" y da click en "Permitir"</span>
                </li>

                <li className='text-left mb-4'>
                  <span>Regresa a la pestaña de Socio Infonavit y da clic en el ícono </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={22} height={22} display="inline-block" style={{ float: 'initial' }}><path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 9.25022 5.38734 6.82447 7.50024 5.38451L7.5 8H9.5V2L3.5 2V4L5.99918 3.99989C3.57075 5.82434 2 8.72873 2 12Z"></path></svg>
                  <span> para actualizar la página</span>
                </li>
              </ol>
            </div>
          )}

          <Button variant="solid" type="button" onClick={() => setShowGeolocationModal(false)}>Aceptar</Button>
        </Modal>
      )}
    </>
  );
};

export default SearchFilters;