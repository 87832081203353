import React, { useState, useEffect } from 'react';
import { articlesAPI } from '../../api/modules/articles';
import Slider from 'react-slick';
import ArticleItem from '../ArticleItem/ArticleItem';
import './styles.css';

interface Article {
	id: number;
	name: null | string;
	active: boolean;
	position: number;
	background: undefined | string;
	title: string;
	button_text: null | string;
	link: undefined | string;
}

const ArticlesThumbnails = () => {
	const [articles, setArticles] = useState<Array<Article>>([]);
	const [isMobile, setIsMobile] = useState(false);

	// Settings for mobile slider
	const settings = {
		className: 'articles-slider',
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const staticArticle: Article = {
		active: true,
		background: "/assets/images/articles/spotify-infonavit-facil.png",
		button_text: "Escuchar ahora",
		id: 3,
		link: "https://open.spotify.com/show/3gUlQtXksmtoyEQvcxdVfH",
		name: "Escucha nuestro podcast",
		position: 1,
		title: "Escucha nuestro podcast",
	};

	const articlesFunction = async () => {
		const res = await articlesAPI.articles();
		setArticles(res.data);
	};

	const _renderArticles = (articles) => articles.map(
		(article: Article, index: number) => {
			// Button copy for spotify article
			if (index === 3) {
				article.button_text = "Escuchar ahora";
			}

			return (
				<div key={article.id} className="col-12 col-md-3  px-md-0">
					<ArticleItem data={article} />
				</div>
			);
		});

	// Get articles and add event listener to resize
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1200) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		articlesFunction();
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// Mobile component
	if (isMobile) return (
		<Slider {...settings}>
			{_renderArticles(articles)}
		</Slider>
	);

	// Desktop component
	return (
		<div className="row">
			{_renderArticles(articles)}
		</div>
	);
};

export default ArticlesThumbnails;
