import { useState, useEffect} from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';
import news1 from '../../assets/img/newsletter/suscribete2.png';
import { newsletterAPI } from '../../api/modules/newsletter';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';


/*type Props = {
	token: string | undefined;
	id: number | undefined;
};*/
const Newsletter = () => {
	const classes = useStyles();
	const [closeModal, setCloseModal] = useState<boolean>(true);
	const [selectedOption, setSelectedOption] = useState<string>('');
	const [option, setOption] = useState<string>('');
	const { user } = useSelector((state: RootState) => state.session);
	const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
	const dispatch = useDispatch();

	const closeModalFunction = () => {
		setCloseModal(!closeModal);
		
		/*if (selectedOption === 'yes') {
			setOption('yes');
		} else setOption('no');*/
		
		
	};
	//la funcion solo se ejecutara cuando selectOpction=yes o sea el button siguiente ejecuta dos
	const answer = async () => {
		
		await newsletterAPI.updateMemberNewsletterSuscription(
			id,
			selectedOption == 'yes',
			token
		).then(function(data) {
			const userNew = data;
			dispatch(actions.updateUser({
				 		...userNew.data,
						email: user?.email,
						role: user?.role,
						token: user?.token,
						sign_in_count: user?.sign_in_count,
				 	}))
		}).finally(() => {
			setOption(selectedOption);
			//dispatch(actions.setIsOpenNewsletter())
		});
	};
	
	return (
		<>
			{closeModal && (
				<Box className={classes.background}>
					<Box className={classes.container}>
						<div className={classes.containerData}>
							<div className={classes.divText}>
								<h1 className={classes.title}>¡Suscríbete!</h1>
								<img src={news1} className={classes.image} />
								<p className={classes.text}>
									Entérate antes que todos y con información oficial. Recibe todas las noticias
									sobre productos y servicios del Infonavit a tu correo.
								</p>
							</div>
							<div className={classes.divButton}>
								<div className={classes.selection}>
									<button
										onClick={() => setSelectedOption('yes')}
										className={`${selectedOption === 'yes'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#000000',
										}}
									>
										Sí
									</span>
								</div>
								<div className={classes.selection}>
									<button
										onClick={() => setSelectedOption('no')}
										className={`${selectedOption === 'no'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#414141',
										}}
									>
										No
									</span>
								</div>
							</div>
							<Button
								onClick={() => {
									answer();
									closeModalFunction();
								}}
								className={`${classes.button} ${classes.buttonMdf}`}
								variant="contained"
								disabled={selectedOption == ''}
								style={{ borderRadius: '14px' }}
							>
								<span className={classes.span}>Siguiente</span>
							</Button>
							<p className={`${classes.text} ${classes.nota}`}>
								Nota: Puedes modificar tu suscripción en el momento que
								quieras desde la sección Preferencias Newsletter que se
								encuentra en el apartado Perfil en la esquina superior
								derecha.
							</p>
						</div>
					</Box>
				</Box>
			)}
		</>
	);
};

export default Newsletter;
