import { StyledBrandCard, BrandCardImage, BrandCardContent, BrandCardTitle, BrandCardDescription, BrandCardLink } from './styles';

const BrandCard = ({ data, ...props }: any) => {

  return (
    <StyledBrandCard>
      <BrandCardLink href={`/resultados?brands=${data?.id}`}>
        <BrandCardImage>
          <img src={data?.mini_logo_full_path} alt={data?.name} />
        </BrandCardImage>
        <BrandCardContent>
          {/* <BrandCardTitle>{data?.name}</BrandCardTitle> */}
          <BrandCardDescription>{data?.description}</BrandCardDescription>
        </BrandCardContent>
      </BrandCardLink>
    </StyledBrandCard>
  );
};

export default BrandCard;