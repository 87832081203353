import API from '..';
class NewsletterAPI extends API {
	updateMemberNewsletterSuscription(
		id: undefined | string | number,
		subscribed: string | boolean,
		token: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: '/v1/member/update_member/',
				method: 'PUT',
				data: {
					subscribed_to_newsletter: `${subscribed}`,
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	updateMemberTour(
		id: undefined | number,
		tour: string | boolean,
		token: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/update_member/`,
				method: 'PUT',
				data: {
					tour: `${tour}`,
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
}
export const newsletterAPI = new NewsletterAPI('');
