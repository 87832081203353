// Dependencies
import React from 'react';

// Styles
import { StyledButton } from './styles';

interface IButton {
	id?: string;
	label?: string | React.ReactElement;
	onClick?: (e: any) => void;
	variant?: 'solid' | 'outline' | 'squareSolid' | 'squareOutline';
	size?: 'small' | 'medium' | 'large';
	state?: 'default' | 'loading' | 'success' | 'error';
	style?: React.CSSProperties;
	disabled?: boolean;
	fluid?: boolean;
	children?: any;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	as?: string;
	href?: string;
	rel?: string;
	target?: string;
}

const Button = ({ onClick, label, children, variant, state, style, size, disabled, fluid, className, ...props }: IButton) => {
	const variantName = variant || 'solid';

	return (
		<StyledButton
			onClick={onClick}
			variant={variantName}
			size={size || 'medium'}
			state={state || 'default'}
			fluid={fluid}
			disabled={disabled}
			style={style}
			className={`${variantName} ${className}`}
			{...props}
		>
			{children || label}
		</StyledButton >
	);
}

export default Button;
