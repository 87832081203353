import styled from "@emotion/styled";

export const Search: any = styled.div`
  position: relative;
  padding: 0;
  margin-bottom: 30px;
  z-index: 9;
  background-color: white;

  @media (max-width: 768px) {
    ${({ open }: any) => open && `z-index: 11;`}
    margin-left: -12px;
    margin-right: -12px;
  }
`;

export const FiltersContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 768px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    background-color: white;

    ${({ open }: any) => open && `display: flex; z-index: 10;`}

    > div,
    button,
    button + div {
      width: 100%;
    }
  }
`;

export const Icon = styled.img`
  display: inline-flex;
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
  margin-left: 16px;
`;

export const Label = styled.span`
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// Styles

export const queryButtonStyles = {
  display: 'inline-flex',
  aligItems: 'center',
  backgroundColor: 'black',
  color: 'white',
  maxWidth: '200px',
  textOverflow: 'ellipsis'
};

export const checkboxStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  // border: '1px solid #DEE0E4',
  borderRadius: '7px',
  paddingLeft: '36px',
  paddingRight: '16px',
  gap: '8px',
  height: '42px',
  // Input
  input: {
    marginTop: '0',
  }
};

export const resetButtonStyles = {
  borderColor: 'black',
  color: 'black'
};

// Styles for Modal
export const modalContainerStyles: any = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  maxWidth: '440px',
  textAlign: 'left',
};