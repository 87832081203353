import styled from "@emotion/styled";

export const Article = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  text-decoration: none;
  margin: 0 12px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.05);
    border: 1px solid #dfdfdf;
  }
`;

export const Image = styled.img`
  display: flex;
  width: 110px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const Title = styled.h4`
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin: 0;
  transition: all 0.2s ease-in-out;
`;

export const Action = styled.span`
  color: #016BC3;
  font-size: 12px;
  font-weight: 500;
`;