import { Formik } from 'formik';
import MaterialField from '../form/MaterialField/MaterialField';
import Button from '../Button';
import styles from './Searcher.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { actions } from '../../store/modules/session';

const Searcher = ({ className }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.session);


  const handleSubmit = (values: any) => {
    if (!user) {
      dispatch(actions.setIsOpenLogin(true));
      return;
    }

    navigate(`/resultados${values.query.length ? `?query=${values.query}` : ''}`);
  };

  const toggleWiggle = (formik) => {
    if (formik?.errors?.query === "") {
      return styles.wiggle;
    }
  };

  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <div className={className}>
          <form onSubmit={formik.handleSubmit}>
            <div id="searcher" className={`${styles.searcher} ${toggleWiggle(formik)}`}>
              <MaterialField
                name="query"
                placeholder="Buscar Benevit"
                type="text"
                icon="search"
                className={styles.field}
              />

              <Button
                variant="solid"
                type="submit"
                className={styles.button}
              >
                <img className={styles.searcherIcon} src="/assets/icons/searcher-icon.svg" alt='Searcher' />
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik >
  );
}
export default Searcher;