import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { textAlign } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			backgroundColor: theme.palette.common.white,
			padding: theme.spacing(3),
			paddingLeft: 50,
			paddingRight: 50,
			color: '#506278',
			borderTop: '1px solid #EDEDED',
			// boxShadow: '0px -4px 30px rgb(50 50 50 / 10%)',
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: 30,
				paddingRight: 30,
			},
		},
		socialMediaSectionRoot: {
			marginBottom: theme.spacing(5),
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(2),
				width: '75%',
				margin: 'auto',
			},
		},

		divLinks: {
			width: '100%',
			display: 'flex',
			alignItems: 'start',
			justifyContent: 'start',
			gap: 3,
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				alignItems: 'center',
			},
		},
		divider: {
			width: '100%',
		},

		list: {
			display: 'flex',
			alignItems: 'center',
			width: 300,
			[theme.breakpoints.down('md')]: {
				width: 200,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				'& .MuiList-root': {
					margin: 'auto',
				},
			},
		},

		buttons: {
			marginTop: theme.spacing(4),
			[theme.breakpoints.down('sm')]: {
				height: 'auto',
				marginTop: theme.spacing(3),
			},
		},

		image: {
			width: '95%',
			maxWidth: 150,
		},
		divInformation: {
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
			},
		},
		gridItem: {
			[theme.breakpoints.down('sm')]: {
				width: '50%',
			},
		},
		gridSocial: {
			[theme.breakpoints.down('sm')]: {
				height: 160,
			},
		},
		gridButton: {
			[theme.breakpoints.down('sm')]: {
				height: 155,
			},
		},
		gridAlign: {
			marginTop: 30,
			[theme.breakpoints.down('sm')]: {
				display: 'flex',
				marginTop: 2,
			},
		},
		alignCenter: {
			textAlign: 'center',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '50% !important',
			},
		},

		iconapp: {
			width: '25px'
		},

		iconmargin: {
			marginRight: '10px'
		}
	})
);
