import API from '..';
class NotificationAPI extends API {
	saveToken(
		playerID: string,
		userID: number,
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: '/v3/admin/register-device',
				method: 'POST',
				data: {
					playerID: playerID,
                    userID: userID,
                    os: 'web'
				},
				headers: {
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
}
export const notificationAPI = new NotificationAPI('');