import styled from '@emotion/styled';

export const Form = styled.form``;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  > div {
    label {
      padding: 6px 12px;
      align-items: center;

      small {
        display: block;
        font-size: 12px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const GroupLabel = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const Required = styled.span`
  color: red;
`;

export const RadioLabel = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 3px 0;
  font-size: 12px;
  color: red;
`;

// Styles for Modal
export const modalContainerStyles: any = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  maxWidth: '530px',
  textAlign: 'center',
};

export const modalImageStyles = {
  width: '100%',
  maxWidth: '84px',
  height: 'auto',
};

export const modalTextStyles: any = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  'h2': {
    color: '#000',
    fontSize: '24px',
    fontWeight: 700,
  },
}
