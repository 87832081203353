import { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { carouselAPI } from '../../api/modules/carousel';

type IFullWidthLoader = {
	open: boolean;
};

const FullWidthLoader = ({ open }: IFullWidthLoader) => {
	const banner = async () => {
		const res = await carouselAPI.banners();
	};
	useEffect(() => {
		banner();
	}, []);
	return (
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
			open={open}
		>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};

export default FullWidthLoader;
