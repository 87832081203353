import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Slide: any = styled.div`
	display: flex;
	width: 100%;
	height: auto;
	align-items: center;
`;

export const SlideImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;
`;

export const SlideContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Heading = styled.h2`
	font-size: 36px;
	font-weight: 700;
`;

export const Description = styled.p`
	font-size: 24px;
	font-weight: 400;
`;

export const Arrow: any = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 50%;
	z-index: 9;
	transform: translateY(-50%);
	width: 35px;
	height: 35px;
	cursor: pointer;

	${(props: any) => props?.direction === 'prev' && `
		left: 22px;
	`}

	${(props: any) => props?.direction === 'next' && `
		right: 22px;
	`}
`;

export const ArrowIcon = styled.img`
	width: 35px;
	height: 35px;
`;