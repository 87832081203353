import styled from "@emotion/styled";

export const StyledBrandCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;  
`;

export const BrandCardLink = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  text-decoration: none;
  color: black;

  &:hover {
    box-shadow: 0 6px 10px rgba(0,0,0,0.05);
  }
`;

export const BrandCardImage = styled.div`
  display:flex;
  border-bottom: 1px solid #EBEBEB;

  img {
    display: flex;
    width: 100%;
    height: 140px;
    object-fit: scale-down;
    object-position: center center;
    padding: 24px;
  }
`;

export const BrandCardContent = styled.div`
  padding: 20px;
`;

export const BrandCardTitle = styled.h3``;

export const BrandCardDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%
  margin: 0;
`;

