import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 660px;
`;

export const Banner = styled.img`
  width: 100%;
  height: auto;
overflow: hidden;
border-radius: 8px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 20px;
  font-weight: 400;
`;

export const VideoContainer = styled.div`
  position: relative;
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  svg {
    width: 60px;
    height: 60px;
  }
`;