import YouTubeIcon from '@mui/icons-material/YouTube';
import { useStyles } from './styles';
import CustomPaper from '../CustomPaper';
import { Typography, Box } from '@mui/material';
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinShareButton,
	LinkedinIcon,
	EmailIcon,
	WhatsappIcon,
} from 'react-share';

interface ISocialIcons {
	isCard?: boolean;
	benevit?: any;
}

const SocialIcons = ({ isCard = false, benevit }: ISocialIcons) => {
	const classes = useStyles();

	return (
		<>
			{!isCard ? (
				<div className={classes.iconsContainer}>
					<FacebookShareButton
						openShareDialogOnClick={true}
						url={`https://app.socioinfonavit.com?share_id=${benevit.id}`}
						quote="¡Te esperan los mejores descuentos en Socio Infonavit! Ahorra y disfruta con todos los Benevits y descuentos que tenemos preparados para ti.Regístrate gratis aquí."
						hashtag="#socioinfonavit"
					>
						<FacebookIcon
							className={`${classes.icon} ${classes.facebookIcon}`}
						/>
					</FacebookShareButton>
					<TwitterShareButton
						openShareDialogOnClick={true}
						url="https://app.socioinfonavit.com"
						title="¡Te esperan los mejores descuentos en Socio Infonavit! Ahorra y disfruta con todos los Benevits y descuentos que tenemos preparados para ti.Regístrate gratis aquí."
						hashtags={['socioinfonavit']}
					>
						<img src="/assets/icons/share-x.svg" alt="X" className={`${classes.icon} ${classes.twitterIcon}`} />
					</TwitterShareButton>
					<EmailShareButton
						openShareDialogOnClick={false}
						subject="Socio Infonavit"
						url=""
						body="¡Hola! Me gustaría invitarte a disfrutar de los Benevits que Socio Infonavit tiene preparados para ti. No dejes pasar esta gran oportunidad. Regístrate gratis aquí: https://app.socioinfonavit.com y comienza a disfrutar. Saludos,"
					>
						<EmailIcon className={`${classes.icon} ${classes.emailIcon}`} />
					</EmailShareButton>
					<WhatsappShareButton
						openShareDialogOnClick={true}
						url="https://app.socioinfonavit.com"
						title="¡Hola!Me gustaría invitarte a disfrutar de los Benevits que Socio Infonavit tiene preparados para ti. No dejes pasar esta gran oportunidad. Regístrate gratis aquí y comienza a disfrutar."
					>
						<WhatsappIcon
							className={`${classes.icon} ${classes.whatsappIcon}`}
						/>
					</WhatsappShareButton>
				</div>
			) : (
				<CustomPaper className={classes.custom}>
					<Typography className={classes.title}>Redes sociales</Typography>
					<Box sx={{ mt: 2 }} className={classes.box}>
						<FacebookShareButton
							url="https://www.facebook.com/ComunidadInfonavit"
							title="¡Hola!Me gustaría invitarte a disfrutar de los Benevits que Socio Infonavit tiene preparados para ti. No dejes pasar esta gran oportunidad. Regístrate gratis aquí y comienza a disfrutar."
							quote={'Socio Infonavit'}
						>
							<FacebookIcon size={29} round={true} />
						</FacebookShareButton>
						<TwitterShareButton
							url="https://twitter.com/Infonavit"
							title="¡Hola!Me gustaría invitarte a disfrutar de los Benevits que Socio Infonavit tiene preparados para ti. No dejes pasar esta gran oportunidad. Regístrate gratis aquí y comienza a disfrutar."
						// quote={"Socio Infonavit"}
						>
							<img src="/assets/icons/share-x.svg" alt="X" className={`${classes.icon} ${classes.twitterIcon}`} />
						</TwitterShareButton>

						<LinkedinShareButton
							url="https://www.linkedin.com/company/infonavit/?originalSubdomain=mx"
							title="¡Hola!Me gustaría invitarte a disfrutar de los Benevits que Socio Infonavit tiene preparados para ti. No dejes pasar esta gran oportunidad. Regístrate gratis aquí y comienza a disfrutar."
						// quote={'Socio Infonavit'}
						>
							<LinkedinIcon size={29} round={true} />
						</LinkedinShareButton>

						<YouTubeIcon
							onClick={() =>
								window.open(
									'https://www.youtube.com/user/ComunidadInfonavit',
									'_blank'
								)
							}
							className={`${classes.icon} ${classes.youtubeIcon}`}
						/>
					</Box>
				</CustomPaper>
			)}
		</>
	);
};

export default SocialIcons;
