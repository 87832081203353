import API from '..';
class CoordsAPI extends API {
	getCoords(token: string): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: '/v3/member/getCoors',
				method: 'GET',
				headers: {
					Authorization: token,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
}
export const coordsAPI = new CoordsAPI('');
