import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	content: {
		textAlign: 'center',
		position: 'relative',
	},
	benevitsContainer: {
		justifyContent: 'space-between',
	},
	benevitContainer: {
		maxWidth: 256,
		margin: 'auto',
		padding: '50px 10px',
	},
}));
