import { useEffect, useState } from 'react';
import BrandList from "../../components/BrandList/BrandList";
import { useSearchParams } from 'react-router-dom';
import { Description } from "../Allies/styles";
import { Title } from "../BenevitsPage/styles";
import { brandsAPI } from '../../api/modules/brands';
import BrandFilters from '../../components/BrandFilters/BrandFilters';

const Brands: any = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [brands, setBrands] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const _getItemsWithParams = async (query: URLSearchParams) => {
    setIsLoading(true);

    const res = await brandsAPI.brandsFilter(query?.get('query')).then((response) => {
      // sort alphabetically ignoring case, accents and special characters
      response?.data.sort((a: any, b: any) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }));
      setBrands(response?.data);
      return response?.data;
    }).catch((error) => {
      console.log("error", error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    _getItemsWithParams(searchParams);
  }, [searchParams]);


  return (
    <div className="container">
      <div className="row">
        <div className="col-12 py-4">
          <Title>Marcas</Title>
          <Description>Encuentra tu marca favorita</Description>
        </div>

        <div className="col-12">
          <BrandFilters searchParams={searchParams} setSearchParams={setSearchParams} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {!isLoading && <BrandList data={brands} />}
        </div>
      </div>
    </div>
  );
};

export default Brands;