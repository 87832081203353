import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// [theme.breakpoints.down('md')]: {
		// padding: '85px 0px 23px 0px',
		// fontSize: '20px',
		// },
		mainContainer: {
			padding: '0px 40px',
			justifyContent: 'center',
			'&>*': {
				margin: ' auto',
			},
		},
		backButtonContainer: {
			margin: '36px auto 47px 36px',
		},
		benevitTitle1: {
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
			'&>p': {
				margin: 'auto',
				marginBottom: 51,
				[theme.breakpoints.down('md')]: {
					marginTop: 30,
				},
			},
		},
		rightContainer: {
			[theme.breakpoints.down('md')]: {
				paddingLeft: 10,
				paddingRight: 10,
				width: '100vw',
				'&>*': {
					marginLeft: 'auto',
					marginRight: 'auto',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				},
			},
		},
		benevitTitle2: {
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		iconsContainer: {
			display: 'flex',
			flexDirection: 'row',
			margin: '11px 0px 11px 20px',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center',
			},
		},
		iconContainer: {
			width: 33,
			height: 33,
			marginRight: 10,
		},
		roundedIcon: {
			borderRadius: 50,
		},
		codeButton: {
			width: '264px',
		},
		social1: {
			marginBottom: 47,
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
			[theme.breakpoints.down('md')]: {
				marginBottom: 20,
			},
		},
		social2: {
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		disponibilidad: {
			[theme.breakpoints.down('md')]: {
				margin: '20px auto !important',
			},
		},
	})
);
