import { useState } from 'react';
import styles from './FaqCard.module.scss';

const FaqCard = ({ question, answer, className }: any) => {
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(s => !s);
  }

  return (
    <div className={`${styles.card} ${className}`}>
      <button
        type='button'
        className={`${styles.toggle} ${show ? styles.toggleActive : ''}`}
        onClick={handleToggle}
      >
        <span className={show ? styles.iconMinus : styles.iconPlus}></span>
      </button>
      <h3>{question}</h3>
      <div
        className={`${styles.answer} ${show ? styles.show : ''}`}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </div>
  );
};

export default FaqCard;