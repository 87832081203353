import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
import { getBrowserType } from './utils/regexFunctions';

const firebaseConfig = {
	apiKey: 'AIzaSyCBYWyV9aXfM3rhlvXx4AeP0SoOJPnk8Uo',
	authDomain: 'infonavitsocio-f9989.firebaseapp.com',
	projectId: 'infonavitsocio-f9989',
	storageBucket: 'infonavitsocio-f9989.appspot.com',
	messagingSenderId: '294014643812',
	appId: '1:294014643812:web:cd16c733938d94cd533971',
	measurementId: 'G-7EHGJZDD3R',
};

const app =
	getBrowserType() !== 'Unknown Browser' &&
	getBrowserType() !== 'Facebook Browser' &&
	initializeApp(firebaseConfig);

const analytics =
	getBrowserType() !== 'Unknown Browser' &&
	getBrowserType() !== 'Facebook Browser' &&
	getAnalytics(app);

export const messaging =
	getBrowserType() !== 'Unknown Browser' &&
	getBrowserType() !== 'Facebook Browser' &&
	app &&
	getMessaging(app);
