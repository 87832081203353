import { Button, Modal, Paper, Box } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Text from '../Text/Text';
import VideoControlButton from './components/VideoControlButton/VideoControlButton';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import MoreInformationModal from './components/MoreInformationModal/MoreInformationModal';

type Props = {
	open: boolean;
	onCancel: () => void;
	handleUnlockBenevit: (answers?: number[]) => void;
	url: any;
	campaingURL: string;
	isMobileVideo?: boolean;
	light: boolean;
	// onMoreInfoClick: () => void;
};

const VideoPlaybackModal = ({
	open,
	onCancel,
	url,
	handleUnlockBenevit,
	campaingURL,
	isMobileVideo,
	light
}: // onMoreInfoClick,
	Props) => {
	const classes = useStyles();
	const videoPlayerRef = useRef<ReactPlayer>(null);
	const [videoDidFinish, setVideoDidFinish] = useState<boolean>(false);
	const [urlVideo, setUrlVideo] = useState<string>('');
	const [mobileContainer, setMobileContainer] = useState<boolean>(false);
	const [modalContainer, setModalContainer] = useState<boolean>(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handlePlayAgain = (): void => {
		videoPlayerRef.current?.seekTo(0);
		//videoPlayerRef.current?.playing(true)
		setVideoDidFinish(false);
	};

	const handleMoreInfo = (): void => {
		// onMoreInfoClick();
	};

	const handleUnlock = (): void => { };
	useEffect(() => {
		if (isMobileVideo) {
			if (url?.content_mobile) {
				setModalContainer(false);
				setMobileContainer(true);
				setUrlVideo(url?.content_mobile);
			} else {
				setMobileContainer(false);
				setModalContainer(true);
				setUrlVideo(url?.content);
			}
		} else {
			setModalContainer(false);
			setMobileContainer(false);
			setUrlVideo(url?.content);
		}
	}, []);

	return (
		<>
			<Modal open={open} hideBackdrop className={classes.modalRoot}>
				<Paper
					className={`${modalContainer
						? classes.mobileModalContainer
						: classes.modalContainer
						}`}
				>
					<span className={classes.modalTitle}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(133,77,14,1)" width="24px" height="24px"><path d="M2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM4 5V19H20V5H4ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459Z"></path></svg>
						Mira el video completo para obtener tu Benevit.
					</span>

					<Box
						className={`${mobileContainer
							? classes.mobileVideoContainer
							: classes.videoContainer
							}`}
					>
						{!videoDidFinish ? (
							<ReactPlayer
								ref={videoPlayerRef}
								url={urlVideo}
								onEnded={() => setVideoDidFinish(true)}
								width="65vw"
								height="65vh"
								style={{ display: !videoDidFinish ? 'block' : 'none' }}
								playing={!videoDidFinish}
								controls
								light={light}
							//playIcon={<img src="https://picsum.photos/200/300"/>}
							/>
						) : (
							<Box
								className={classes.playerButtonsContainer}
								style={{ display: videoDidFinish ? 'flex' : 'none' }}
							>
								<VideoControlButton
									buttonProps={{
										onClick: handlePlayAgain,
									}}
								>
									VOLVER A REPRODUCIR
								</VideoControlButton>
								<VideoControlButton
									buttonProps={{
										onClick: () => window.open(campaingURL, '_blank'),
									}}
								>
									MÁS INFORMACIÓN
								</VideoControlButton>
								<VideoControlButton
									buttonProps={{
										onClick: () => handleUnlockBenevit(),
									}}
								>
									DESBLOQUEAR
								</VideoControlButton>
							</Box>
						)}
					</Box>
					<Box className={classes.cancelButtonContainer}>
						<Button onClick={onCancel} style={{ textTransform: 'none' }}>
							<Text
								color="#e61737"
								fontSize={!isMobile ? 20 : 16}
								textAlign="center"
							>
								Cancelar
							</Text>
						</Button>
					</Box>
				</Paper>
			</Modal>
		</>
	);
};

export default VideoPlaybackModal;
