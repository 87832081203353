import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: '#F7F7F71F !important  ',
      minWidth: '176px !important',
      '&:hover': {
        backgroundColor: '#F7F7F74D !important',
      },
    }
  })
);
