import React, { useState, useEffect } from 'react';
import { BannerContainer, Banner, Title, Description, Logo } from './styles';
import { getCategoryById, getBrandById, isFilterInUrl, getValuesByType } from '../../utils/searchFunctions';
import { brandsAPI } from '../../api/modules/brands';
import styles from './SearchBanner.module.scss';

const SearchBanner = ({ values, data, query, categories, noResults, brands }: any) => {
  const [category, setCategory] = useState<any>(null);
  const [brand, setBrand] = useState<any>(null);
  const [brandsData, setBrandsData] = useState<any>([]);
  const params = new URLSearchParams(window.location.search);
  const categoryId = params?.get('categories')?.split(',')[0] || '';
  const brandId = params?.get('brands')?.split(',')[0] || '';

  const _fetchBrands = async () => {
    const resBrands = await brandsAPI.brandsFilter();
    setBrandsData(resBrands?.data);
  }

  // Fetch brands data
  useEffect(() => {
    _fetchBrands();
  }, []);


  // Set category and brand
  useEffect(() => {
    const brandsFilters = getValuesByType('brands', values?.filters);
    const categoriesFilters = getValuesByType('categories', values?.filters);
    if (categoriesFilters.length > 0) {
      setCategory(getCategoryById(categoryId, categories));
    }


    if (brandsFilters.length > 0) {
      setBrand(getBrandById(brandsFilters[0], brandsData));
    }
  }, [values, categories, brandsData]);

  if (noResults > 0) {
    return (
      <Banner>
        <Title>No se encontraron coincidencias</Title>
      </Banner>
    )
  }

  // Show search banner
  if (query && noResults === 0) return (
    <Banner>
      <Title>Resultados para "{query}" {data && (`(${data.length})`)}</Title>
    </Banner>
  );

  // Show category banner
  if (params.get('categories') && params?.get('categories')?.split(',').length === 1 && !params?.get('brands') && isFilterInUrl(categoryId, 'categories', params)) {
    return (
      <Banner className={styles.bannerTitle}>
        <Title>
          {category?.label === 'Promociones relámpago' ? 'Benevits relámpago' : category?.label}
        </Title>
      </Banner>
    );
  }

  // Show brand banner
  if (params.get('brands') && params?.get('brands')?.split(',').length === 1 && !params?.get('categories') && isFilterInUrl(brandId, 'brands', params)) {
    return (
      <BannerContainer>
        <Banner outline={true}>
          <Logo src={brand?.mini_logo_full_path} alt={brand?.name} />
          <Description>{brand?.description}</Description>
        </Banner>
      </BannerContainer>
    );
  }

  return null;
}

export default SearchBanner;