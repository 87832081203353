import { useEffect, useRef, useState } from 'react';
import AlliesForm from '../../components/AlliesForm/AlliesForm';
import { Container, Title, Description, VideoContainer, PlayButton } from './styles';
import { alliesAPI } from '../../api/modules/allies';
import { url } from 'inspector';

const Allies = () => {
  const videoRef = useRef<any>('');

  const [controls, setControls] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const isImage = (url: any) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const fetchVideo = async () => {
    try {
      const response = await alliesAPI.getVideo();
      console.log(response?.data?.url);
      setVideoUrl(response?.data?.url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchVideo();
    }

    return () => { isMounted = false; }
  }, [])

  return (
    <Container className='container'>
      <div className='row py-4'>
        <div className='col-12'>
          <Title className="m-0 mb-3">¿Quieres ser aliado?</Title>

          {videoUrl && (
            <>
              {isImage(videoUrl) && <img src={videoUrl} alt="banner" className="w-100" />}
              {!isImage(videoUrl) && (
                <VideoContainer>
                  <video
                    ref={videoRef}
                    width="100%"
                    height="auto"
                    controls={controls}
                    controlsList="nodownload"
                    poster="/assets/images/banner/allies.jpg"
                  >
                    <source src={videoUrl} type="video/mp4" />
                    Tu navegador no soporta video HTML5.
                  </video>

                  {!controls && (
                    <PlayButton onClick={() => {
                      setControls(true);
                      videoRef.current.play();
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M6 20.1957V3.80421C6 3.01878 6.86395 2.53993 7.53 2.95621L20.6432 11.152C21.2699 11.5436 21.2699 12.4563 20.6432 12.848L7.53 21.0437C6.86395 21.46 6 20.9812 6 20.1957Z"></path></svg>
                    </PlayButton>
                  )}
                </VideoContainer>
              )}
            </>
          )}

          <Description>Llega a más clientes con Socio Infonavit y ofrece descuentos, ofertas y Benevits a millones de derechohabientes.</Description>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <AlliesForm />
        </div>
      </div>
    </Container>
  )
};

export default Allies;