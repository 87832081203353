import { IUser } from '../../interfaces/user';
import { is } from 'date-fns/locale';

export interface SesionState {
	user: IUser | null;
	isLogged: boolean;
	isOpenNewsletterModal: boolean;
	isOpenLoginModal: boolean;
	isLogin: boolean;
	userKey: string | null;
}

export const SET_USER = 'session/setUser';
export const SIGN_OUT = 'session/signOut';
export const SIGN_IN = 'session/signIn';
export const UPDATE_USER = 'session/updateUser';
export const CHANGE_REMEMBER = 'session/changeRemember';
export const IS_OPEN_NEWSLETTER = 'session/isOpenNewsletter';
export const IS_OPEN_LOGIN = 'session/isOpenLogin';
export const IS_LOGIN = 'session/isLogin';
export const USER_KEY = 'session/userKey';

export interface SignInProps {
	email: string;
	password: string;
}

export const INITIAL_STATE: SesionState = {
	user: null,
	isLogged: false,
	isOpenNewsletterModal: true,
	isOpenLoginModal: false,
	isLogin: false,
	userKey: null,
};
