import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)({
	width: '100%',
	maxWidth: 430,
	textTransform: 'none',
	fontSize: 16,
	padding: '6px 12px',
	borderRadius: 20,
	margin: '20px auto',
});
