import { Button, Modal, Paper, Box, CardMedia } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Text from '../../../Text/Text';
import InfoIcon from '@mui/icons-material/Info';
import qAndAImage from '../../../../assets/img/actividades-a-financiar.png';
import UnlockBenevitBackdrop from '../../../UnlockBenevitBackdrop/UnlockBenevitBackdrop';

type Props = {
	// open: boolean;
	slide?: any;
	onGoToBenevit: () => void;
};

const MoreInformationModal = ({ onGoToBenevit, slide }: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const isPortrait = useMediaQuery(theme.breakpoints.down('md'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		// <Modal
		// 	open={open}
		// 	BackdropComponent={UnlockBenevitBackdrop}
		// 	className={classes.modalRoot}
		// >
		<Paper className={classes.modalContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.header}>
					<InfoIcon
						className={classes.infoIcon}
						fontSize={isMobile || isPortrait ? 'small' : 'large'}
					/>
					<Text
						fontSize={isPortrait || isMobile ? 16 : 35}
						color="#0126C3"
						fontWeight="bold"
						margin={`${isPortrait || isMobile ? '3' : '7'}px 0px 0px 12px`}
					>
						Más información
					</Text>
				</Box>
				{slide?.image && (
					<CardMedia
						component="img"
						src={slide?.image}
						className={classes.image}
					></CardMedia>
				)}
				<Text fontSize={isPortrait || isMobile ? 14 : 20}>
					<span
						id="instructions"
						dangerouslySetInnerHTML={{
							__html: slide?.question_text,
						}}
					/>
				</Text>
				<Box className={classes.buttonContainer}>
					<Button
						variant="contained"
						className={classes.button}
						onClick={onGoToBenevit}
					>
						Continuar
					</Button>
				</Box>
			</Box>
		</Paper>
		// </Modal>
	);
};

export default MoreInformationModal;
