import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { notificationAPI } from '../../api/modules/notification';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const notificationActivation = async (id: any) => {
  console.log('MESSAGE', messaging)
  if (id && messaging) {
    const token = await getToken(messaging, {
      vapidKey: 'BHi8afYoPg8rImsILG1GcEmQj_4BByP-UcAezsRzbN3QbW-lZ_NoMnNAIKH75_Lo3kl4LJv4JrsgmvLc8DgQ7Fo'
    }).catch(error => console.log("Error al generar el token"));

    if (token) {
      const tk = localStorage.setItem("notification", token)
      notificationAPI.saveToken(token, id)
    }

    if (!token) { console.log("Notificaciones desactivadas") }
  }
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      height: 80,
      [theme.breakpoints.down('sm')]: {
        height: 74,
      },
    },
    containerToast: {
      backgroundColor: '#FFF',
      color: '#000'
    },
    toast: { position: 'fixed', top: 100, right: 0, zIndex: 2 },
    toastTitle: { color: 'red' }
  })
);

const FirebaseNotification = () => {
  const { user } = useSelector(
    (state: RootState) => state.session
  );
  const id: number | undefined = user?.id;
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = useState<string | undefined>("");
  const [notificationMessage, setNotificationMessage] = useState<string | undefined>("");

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnack(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  // Notifications
  useEffect(() => {
    const saved = localStorage.getItem('notification');
    // if (saved == null && user != null) {
    //   if (getBrowserType() !== 'Unknown Browser' && getBrowserType() !== 'Facebook Browser') {
    //     console.log('ID', id)
    notificationActivation(id);
    //   }
    // }
  }, [user, messaging]);

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, message => {
        setNotificationTitle(message.notification?.title);
        setNotificationMessage(message.notification?.body);
        setOpenSnack(true)
      });
    }
  });

  return (
    <Snackbar
      style={{ background: '#FFF' }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={5000}
      open={openSnack}
      onClose={() => setOpenSnack(false)}
    >
      <SnackbarContent
        className={classes.containerToast}
        message={<div><strong>{notificationTitle}</strong><br />{notificationMessage}</div>}
        action={action}
      />
    </Snackbar>
  );
};

export default FirebaseNotification;