import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { textAlign } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
	iconsContainer: {
		marginTop: 17.2,
		'&>*': {
			marginRight: 4,
		},
	},
	box: {
		display: 'flex',
		justifyItems: 'center',
		alignItems: 'center',
		gap: 20,
		[theme.breakpoints.down('lg')]: {
			gap: 13,
		},
		[theme.breakpoints.down('md')]: {
			gap: 20,
		},
	},
	icon: {
		borderRadius: '50%',
		padding: 3,
		width: 38,
		height: 38,
		marginBottom: 8,
		transition: 'all 0.15s ease-in-out',
	},

	facebookIcon: {
		color: '#fff',
		backgroundColor: '#3B5998',
		'&:hover': {
			boxShadow: '0 0 0 6px #3B5998AE',
		}
	},
	twitterIcon: {
		marginRight: 5,
		display: 'flex',
		color: '#fff',
		backgroundColor: 'black',
		'&:hover': {
			boxShadow: '0 0 0 6px #000000AE',
		}
	},
	emailIcon: {
		color: '#fff',
		backgroundColor: '#7F7F7F',
		'&:hover': {
			boxShadow: '0 0 0 6px #7F7F7FAE',
		}
	},
	whatsappIcon: {
		color: '#fff',
		backgroundColor: '#24D366',
		'&:hover': {
			boxShadow: '0 0 0 6px #24D366AE',
		}
	},
	linkedinIcon: {
		color: '#fff',
		backgroundColor: '#0e76a8',
	},
	youtubeIcon: {
		cursor: 'pointer',
		width: '29px !important',
		height: '29px !important',
		color: '#fff',
		backgroundColor: '#FF0000',
	},
	custom: {
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyItems: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			width: '85%',
			margin: 'auto',
		},
	},
	title: {
		'&.MuiTypography-root': {
			fontSize: 22,
			fontWeight: 700,
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
		},
	},
}));
