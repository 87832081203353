import React, { useState, useEffect } from 'react';
import { DropdownContainer, StyledDropdown, StyledDropdownScroll, buttonStyles, Icon, counterStyles } from './styles';
import Button from '../../Button';
import CheckBox from '../Checkbox/Checkbox';
import { useFormikContext } from 'formik';
import { convertUrlParamsToFormValues, filtersCounter, getOptionsByType, getValuesByType, setFormValues } from '../../../utils/searchFunctions';

const Dropdown = ({ options, name, type, children, onChange, ...props }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, isSubmitting, submitForm, setFieldValue }: any = useFormikContext();

  const _renderOptions = (options: any) => options.map(
    (option: any, index: number) =>
      <CheckBox
        key={`${type}_${option?.value}`}
        id={name + option?.value}
        name={name}
        value={JSON.stringify({ type: type, value: option?.value?.toString() })}
        label={option?.label}
        disabled={isSubmitting}
      />
  );

  // Handle click outsite
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!event?.target?.closest(`#${type}`)) {
        let urlSearchParams = new URLSearchParams(window.location.search);

        setIsOpen(false);

        if (event?.target?.htmlFor || event?.target?.value || (event?.target?.type === 'submit')) {
          return;
        };

        if (
          type !== event?.target?.closest(`.filter-dropdown`)?.id
        ) {
          setFormValues(convertUrlParamsToFormValues(urlSearchParams), setFieldValue);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [name]);

  return (
    <DropdownContainer id={type} className='filter-dropdown'>
      <Button
        type='button'
        variant="squareOutline"
        style={buttonStyles}
        onClick={() => setIsOpen(!isOpen)}
      >
        {children}

        {(filtersCounter(type, values[name]) > 0) ? (
          <small style={counterStyles}>{filtersCounter(type, values[name])}</small>
        ) : null}

        <Icon
          src={process.env.PUBLIC_URL + "/assets/icons/angle-down.svg"}
          alt="angle-down"
          size="small"
          open={isOpen}
        />
      </Button>

      {(options && options?.length > 0 && isOpen) &&
        <StyledDropdown open={isOpen}>
          <StyledDropdownScroll>
            {_renderOptions(options)}
          </StyledDropdownScroll>

          <Button type="submit" onClick={() => {
            setIsOpen(!isOpen);
            submitForm();
          }} disabled={isSubmitting}>Aplicar</Button>
        </StyledDropdown>
      }
    </DropdownContainer>
  );
};

export default Dropdown;