import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { benevitsAPI } from "../../api/modules/benevits";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import close from '../../assets/img/buttom/close.svg';
import styles from './BenevitPopUp.module.scss';
import ItemCardA from "../ItemCardA";
import { categoriesAPI } from '../../api/modules/categories';

const BenevitPopUp = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { user } = useSelector(
    (state: RootState) => state.session
  );

  const getBenevitWithStatus = (
    benevitArr: object[],
    unlocked: boolean | undefined
  ) => {
    let newBenevitArr: any = [];
    benevitArr.map((benevitObj: any) => {
      newBenevitArr.push({ ...benevitObj, unlocked });
    });
    return newBenevitArr;
  };

  // get item with less unlocks_counter
  const getItem = (array: any) => {
    const flashBenevits = array.filter((benevit: any) => {
      return benevit?.categories?.some((category: any) => category?.id === 9);
    })


    const newArray = array.splice(0, 66);

    if (flashBenevits.length > 0) {
      return flashBenevits[Math.floor(Math.random() * flashBenevits.length)];
    }

    // Get ramdom item
    return newArray[Math.floor(Math.random() * newArray.length)];
  }

  const _getBenevits = async () => {
    setData([]);
    if (user === null) {
      setIsLoading(true);
      const fetchData = await benevitsAPI.benevitsNoAuth();
      const lockedNoUser = fetchData.locked;
      // Sort by unlocks_counter asc
      lockedNoUser.sort((a: any, b: any) => a.unlocks_counter - b.unlocks_counter);
      let allBenevitsArray: any = [...getBenevitWithStatus(lockedNoUser, false)];
      // filter territories[index].id includes id 34
      allBenevitsArray = allBenevitsArray.filter((benevit: any) => (benevit.territories.some((territory: any) => territory.id === 34)));
      // console.log("PopUp", allBenevitsArray)
      setData(getItem(allBenevitsArray));
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const res = await benevitsAPI.getAllBenevits(user?.token);
      const locked = await res?.data?.locked;

      let lockedBenevitsArray: any = [
        ...getBenevitWithStatus(locked, false),
      ];

      let index = 0;
      lockedBenevitsArray.map((benevitObj: any) => {
        if (benevitObj.id === 1000) {
          index = lockedBenevitsArray.indexOf(benevitObj);
          lockedBenevitsArray.splice(index, 1);
          lockedBenevitsArray.splice(0, 0, benevitObj);
        }
      });

      setData(getItem(locked));
      setIsLoading(false);
    }

    setShow(showPopUp());
  };

  // Show pop up 1 time by day
  const showPopUp = () => {
    const date = new Date();
    const today = date.toDateString();
    const lastPopUp = localStorage.getItem('lastPopUp');

    if (!lastPopUp || today !== lastPopUp) {
      localStorage.setItem('lastPopUp', today);
      return true;
    }
    return false;
  };

  useEffect(() => {
    _getBenevits();
  }, [])

  if (!show || isLoading || !data) return null;

  return (
    <Modal
      show={show}
      setShow={() => setShow(false)}
      container={{ style: { maxWidth: '360px' } }}
    >
      <div className="container mx-auto ">
        <img
          src={close}
          alt="cerrar"
          onClick={() => setShow(false)}
          className={styles.closeButton}
        />
        <h2 className={`${styles.title} mb-2`}>El beneficio que necesitas lo encuentras aquí</h2>
        <h2 className={`${styles.title} mb-3`}>¡Consíguelo hoy!</h2>
        <ItemCardA data={data} handleSetBenevit={() => {
          // go to
          setShow(false);
          navigate(`/info-benevit/${data?.slug}`);
        }}
        />
      </div>
    </Modal >
  );
};

export default BenevitPopUp;