import React, { useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { StyledCheckbox, StyledLabel } from './styles';
import { set } from 'date-fns';
import { on } from 'events';
import { onMessage } from 'firebase/messaging';

const Checkbox = ({
  id,
  name,
  type,
  label,
  value,
  defaultIcon,
  selectedIcon,
  placeholder,
  tooltip,
  className,
  children,
  validate,
  onChange,
  inputStyles,
  ...props
}: any) => {

  const { values }: any = useFormikContext();
  const [meta] = useField(name);
  const [checked, setChecked] = useState(false);

  // Set checked value
  useEffect(() => {
    const findValue = (values: any, target: string) => values.find((item: any) => item === target);

    // get typeof
    if (typeof values[name] === 'object') {
      if (findValue(meta.value, value.toString())) {
        setChecked(true);
        return;
      }

      setChecked(false);
      return;
    }

    setChecked(meta.value);

  }, [meta.value, name, value]);

  return (
    <StyledCheckbox className="form-check" {...props}>
      <Field
        className="form-check-input"
        type={type || "checkbox"}
        value={value}
        id={id}
        name={name}
        checked={checked}
        disabled={props.disabled || false}
        {...onChange}
        {...inputStyles && { style: inputStyles }}
      />
      <StyledLabel className="form-check-label" htmlFor={id}>
        {label || children}
      </StyledLabel>
    </StyledCheckbox>
  );
};

export default Checkbox;