import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';

const LoginPage = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);

  const handleDispatchLogin = () => {
    dispatch(actions.setIsLogin(true))
    dispatch(actions.setIsOpenLogin(true))
  };

  const handleLogin = () => {
    dispatch(actions.setIsLogin(true));
    dispatch(actions.setUserKey(window.location.search.replace('?', '')));
  };


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleDispatchLogin();

      if (params.toString().length) {
        handleLogin();
        return;
      }

      dispatch(actions.setUserKey(null));
    }

    return () => {
      dispatch(actions.setIsLogin(false));
      dispatch(actions.setIsOpenLogin(false));
      dispatch(actions.setUserKey(null));
      isMounted = false;
    };
  }, []);

  return (
    <></>
  );
};

export default LoginPage;