import API from '..';

class FeaturedBenevitsAPI extends API {
	get(token?: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;

		return this.requestWrapper(
			this.request({
				url: `/v3/public/benevits_selected`,
				method: 'GET',
				headers: {
					...(token && { Authorization: token }),
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
}
export const featuredBenevits = new FeaturedBenevitsAPI('');
