const CircleImg = () => {
	return (
		<>
			<div
				style={{
					bottom: 0,
					left: 0,
					position: 'absolute',
					width: '40vh',
					height: '40vh',
					borderRadius: '0 100% 0 0',
					background: '#E61737',
				}}
			></div>

			<div
				style={{
					bottom: 0,
					left: 0,
					position: 'absolute',
					width: '43vh',
					height: '43vh',
					borderRadius: '0 100% 0 0',
					background: 'rgba(230, 23, 55, 0.1)',
				}}
			></div>

			<div
				style={{
					bottom: 0,
					left: 0,
					position: 'absolute',
					width: '46vh',
					height: '46vh',
					borderRadius: '0 100% 0 0',
					background: 'rgba(230, 23, 55, 0.1)',
				}}
			></div>

			<div
				style={{
					bottom: '42vh',
					left: '8vh',
					position: 'absolute',
					width: '6vh',
					height: '6vh',
					borderRadius: '50%',
					background: '#E61737',
				}}
			></div>

			<div
				style={{
					bottom: '35vh',
					left: '22vh',
					position: 'absolute',
					width: '3vh',
					height: '3vh',
					borderRadius: '50%',
					background: '#E61737',
				}}
			></div>

			<div
				style={{
					bottom: '10vh',
					left: '43vh',
					position: 'absolute',
					width: '2vh',
					height: '2vh',
					borderRadius: '50%',
					background: '#E61737',
				}}
			></div>
		</>
	);
};

export default CircleImg;
