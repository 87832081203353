
import BrandCard from '../BrandCard/BrandCard';

const BrandList = ({ data }: any) => {

  const _renderBrands = (brands: any) => brands.map((brand: any) => {
    if (!brand.active) return null;

    return (
      <div key={brand.id} className='d-flex col-12 col-sm-6 col-md-4 col-lg-4 mb-4'>
        <BrandCard data={brand} />
      </div>
    );
  });

  if (!data) return null;

  return (
    <div className="row">
      {_renderBrands(data)}
    </div>
  );
};

export default BrandList;