import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from './styles';

interface MediaProps {
	loading?: boolean;
}

function Media(props: MediaProps) {
	const classes = useStyles();
	return (
		<Grid container className={classes.benevitsContainer}>
			{Array.from(new Array(12)).map((sk, index) => (
				<Grid key={index} item lg={3} md={6} sm={6} xs={6}>
					<Box className={classes.benevitContainer}>
						{/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Skeleton variant="circular">
								<Avatar />
							</Skeleton>
						</Box> */}
						<Box sx={{ pt: 0.5 }}>
							<Skeleton variant="rectangular" height={220} />
						</Box>
						<Box sx={{ pt: 1.5 }}>
							<Skeleton />
						</Box>
					</Box>
				</Grid>
			))}
		</Grid>
	);
}

export default function CustomSkeleton() {
	return (
		<Box sx={{ overflow: 'hidden' }}>
			<Media loading />
		</Box>
	);
}
