import API from '..';
class BrandsAPI extends API {
	brandsFilter(query?: string | undefined | null): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v3/public/allies`,
				method: 'POST',
				headers: {
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
				...(query && { data: { query: query } }),
			}),
			true
		);
	}
}
export const brandsAPI = new BrandsAPI('');
