import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import JSEncrypt from 'jsencrypt';

const SignupUrlPage = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const LOGIN_PRIVATE_KEY = process.env.REACT_APP_LOGIN_PRIVATE_KEY || '';
  const [userData, setUserData] = useState({});

  const handleDispatchLogin = () => {
    dispatch(actions.setIsLogin(true))
    dispatch(actions.setIsOpenLogin(true))
  };

  const handleSignUp = () => {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(LOGIN_PRIVATE_KEY);
    const value = window.location.search.replace('?', '');
    const decryptData = decrypt.decrypt(value);

    if (decryptData) {
      const data = JSON.parse(decryptData);
      setUserData(data);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleDispatchLogin();

      if (params.toString().length) {

        handleSignUp();
        return;
      }

      dispatch(actions.setUserKey(null));
    }

    return () => {
      dispatch(actions.setIsLogin(false));
      dispatch(actions.setIsOpenLogin(false));
      dispatch(actions.setUserKey(null));
      isMounted = false;
    };
  }, []);

  return (
    <></>
  );
};

export default SignupUrlPage;