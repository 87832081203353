import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import { useLocation } from 'react-router-dom';

const Breadcrumbs = ({ title, data, ...props }: any) => {
  const location = useLocation();
  let currentPath = '';
  const getTitle = (crumb, title) => {
    if (crumb && title) return title;
    if (crumb && !title) return crumb;
    return 'Inicio';
  };

  const crumbs = location.pathname.split('/')
    .filter(crumb => crumb !== '')
    .map((crumb, index) => {
      currentPath += `/${crumb}`;

      if (crumb !== "info-benevit") return (
        <div key={crumb} className={styles.crumb} >
          <Link
            to={currentPath}
            className={index === (location.pathname.split('/').length - 2) ? styles.activeCrumbLink : styles.crumbLink}
          >
            {getTitle(crumb, title)}
            {index < (location.pathname.split('/').length - 2) && (
              <img src="/assets/icons/angle-right.svg" alt="arrow" />
            )}
          </Link>
        </div>
      );
    });

  const crumbsFromData = (data: any) => data.map((crumb: any, index: number) => (
    <div key={crumb?.name} className={styles.crumb} >
      <Link
        to={crumb?.link}
        className={index === (data?.length - 1) ? styles.activeCrumbLink : styles.crumbLink}
      >
        {crumb?.name}
        {index < (data.length - 1) && (
          <img src="/assets/icons/angle-right.svg" alt="arrow" />
        )}
      </Link>
    </div>
  ));

  if (data) {
    return (
      <div className={styles.breadcrumbs}>
        <div className={styles.crumb} >
          <Link to="/" className={styles.crumbLink}>Inicio</Link>
          <img src="/assets/icons/angle-right.svg" alt="arrow" />
        </div>
        {crumbsFromData(data)}
      </div>
    );
  }

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.crumb} >
        <Link to="/" className={styles.crumbLink}>Inicio</Link>
        <img src="/assets/icons/angle-right.svg" alt="arrow" />
      </div>
      {crumbs}
    </div>
  );
};

export default Breadcrumbs;