import styled from "@emotion/styled";

export const StyledCheckbox = styled.div`
  input[type="checkbox"] {
    cursor: pointer;
    border-color: #000;

    &:checked {
      background-color: #000;
      border-color: #000;
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  cursor: pointer;
`;