import API from '..';
import { isEmpty } from '../../utils/expressions';

class FilterBenevitAPI extends API {
	myBenevitFilter(
		token?: string | undefined,
		ally_id?: number | string,
		territory?: number | string,
		category?: number | string
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		let url = '/v1/member/my_benevits?';

		if (!isEmpty(ally_id || 0) && Number(ally_id || 0) > 0) {
			url = `${url}ally=${ally_id}&`;
		}
		if (!isEmpty(territory || 0) && Number(territory || 0) > 0) {
			url = `${url}territory=${territory}&`;
		}
		if (!isEmpty(category || 0) && Number(category || 0) > 0) {
			url = `${url}category=${category}&`;
		}
		const newURL = url.slice(0, -1);
		return this.requestWrapper(
			this.request({
				url: `${newURL}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	searchMyBenevitFilter(
		token: string | undefined,
		query: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/member_benevits/search-exp`,
				method: 'POST',
				data: {
					query: query,
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			false
		);
	}
	landingBrandCarouselFilter(
		token?: string | undefined,
		ally_id?: number | string
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		let url = '/v1/member/landing_benevits?';

		if (!isEmpty(ally_id || 0) && Number(ally_id || 0) > 0) {
			url = `${url}ally=${ally_id}&`;
		}

		const newURL = url.slice(0, -1);
		return this.requestWrapper(
			this.request({
				url: `${newURL}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}

	landingBenevitFilter(
		token?: string | undefined,
		ally_id?: number | string,
		territory?: number | string,
		category?: number | string
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		let url = '/v1/member/landing_benevits?';

		if (!isEmpty(ally_id || 0) && Number(ally_id || 0) > 0) {
			url = `${url}ally=${ally_id}&`;
		}
		if (!isEmpty(territory || 0) && Number(territory || 0) > 0) {
			url = `${url}territory=${territory}&`;
		}
		if (!isEmpty(category || 0) && Number(category || 0) > 0) {
			url = `${url}category=${category}&`;
		}
		const newURL = url.slice(0, -1);
		return this.requestWrapper(
			this.request({
				url: `${newURL}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}

	// searchMyBenevitFilter(
	// 	token: string | undefined,
	// 	query: string | undefined
	// ): Promise<any> {
	// 	const x = Math.floor(Math.random() * 500) + 1;
	// 	return this.requestWrapper(
	// 		this.request({
	// 			url: `/v3/member/benevits/serach-my?query=${query}`,
	// 			method: 'GET',
	// 			headers: {
	// 				Authorization: `${token}`,
	// 				'X-Case': Math.round(new Date().getTime() / 1000) * x,
	// 				'X-Trace': x,
	// 			},
	// 		}),
	// 		false
	// 	);
	// }
	searchlandingBenevitFilter(
		token: string | undefined,
		query: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/member_benevits/search`,
				method: 'POST',
				data: {
					query: query,
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			false
		);
	}

	searchBenevits(query?: URLSearchParams, token?: string | undefined): Promise<any> {
		// Convert string to array
		const categoriesArray = query?.get('categories')?.split(',');
		const brandsArray = query?.get('brands')?.split(',');
		const statesArray = query?.get('states')?.split(',');
		const near = query?.get('near') ?? null;
		const queryParam = query?.get('query') ?? null;
		const order = query?.get('order') ?? null;

		const x = Math.floor(Math.random() * 500) + 1;

		const params = new URLSearchParams();
		if (categoriesArray && categoriesArray.length > 0) {
			categoriesArray.map((category) => params.append('categories[]', category));
		}

		if (brandsArray && brandsArray.length > 0) {
			brandsArray.map((brand) => params.append('brands[]', brand));
		}

		if (statesArray && statesArray.length > 0) {
			statesArray.map((state) => params.append('states[]', state));
		}

		if (near) params.append('near', near);

		if (queryParam) params.append('query', queryParam);

		if (order) params.append('order', order);

		return this.requestWrapper(
			this.request({
				url: `/v3/public/benevits/searchV2${
					params !== undefined && params !== null && params.toString().length
						? `?${params}`
						: ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			false
		);
	}
}
export const filterBenevitAPI = new FilterBenevitAPI('');
