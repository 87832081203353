import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

type Props = {
	sizeTitle?: number;
	alignTitle?:
	| 'start'
	| 'end'
	| 'left'
	| 'right'
	| 'center'
	| 'justify'
	| 'match-parent';
};

export const titleStyles = makeStyles<Theme, Props>((theme) =>
	createStyles({
		title: {
			textAlign: ({ alignTitle }) => alignTitle,
			marginBottom: 50,
			marginTop: 50,
			fontWeight: 800,
			fontSize: ({ sizeTitle }) => sizeTitle,
			[theme.breakpoints.down('md')]: {
				fontSize: '16px !important',
			},
		},
	})
);

export const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		root: {
			marginTop: 0,
			marginBottom: theme.spacing(10),
			// marginTop: theme.spacing(10),
			// marginBottom: theme.spacing(10),
			position: 'relative',
		},
		module: {
			background: '#dd0528',
			padding: '24px',
			borderRadius: '10px',
			overflow: 'hidden',
		},
		content: {
			textAlign: 'center',
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			flex: '1',
			padding: '10px !important',
			[theme.breakpoints.down('sm')]: {
				minHeight: 160,
				padding: '5px !important',
			},
		},
		textCol: {
			position: 'relative',
			zIndex: 1,
		},
		col: {
			zIndex: 2,
		},
		title: {
			position: 'relative',
			fontSize: 24,
			fontWeight: 800,
			color: 'white',
			zIndex: 2,
		},
		subtitle: {
			position: 'relative',
			fontSize: 20,
			color: 'white',
			zIndex: 2,
			textShadow: '0px 0px 6px #dd0528',
		},
		image: {
			position: 'absolute',
			bottom: '-10%',
			right: '-20%',
			width: '100%',
			maxWidth: '300px',
			height: 'auto',
			zIndex: 1,
			[theme.breakpoints.down('sm')]: {
				display: 'none',
				position: 'relative',
				bottom: 'initial',
				right: 'initial'
			},
		},
		expirationDate: {
			marginTop: 'auto !important',
			marginBottom: '0 !important',
		},
		cardAction: {
			justifyContent: 'center',
			borderTop: '2px dashed #F4F4F4',
			padding: '15px 5px',
			marginBottom: '0',
			marginTop: 'auto',
			[theme.breakpoints.down('sm')]: {
				padding: '10px 3px',
			},
		},
		divIsotipo: {
			position: 'absolute',
			top: -38,
			marginRight: 'auto',
			marginLeft: 'auto',
			left: 0,
			right: 0,
			width: 75,
			height: 75,
			borderRadius: 50,
			[theme.breakpoints.down('sm')]: {
				width: 45,
				height: 45,
				top: -23,
			},
		},
		isotipo: {
			position: 'absolute',
			top: 8,
			marginRight: 'auto',
			marginLeft: 'auto',
			left: 0,
			right: 0,
			width: 60,
			height: 60,
			borderRadius: 50,
			[theme.breakpoints.down('sm')]: {
				width: 35,
				height: 35,
			},
		},
		logo: {
			maxWidth: '100%',
			height: 50,
			marginLeft: 'auto',
			marginRight: 'auto',
			marginBottom: theme.spacing(0),
			marginTop: theme.spacing(5),
			[theme.breakpoints.down('sm')]: {
				height: 29,
				marginTop: theme.spacing(3),
			},
		},
		boxText: {
			minHeight: '63px',
			marginBottom: '15px',
		},
		text: {
			fontWeight: 600,
			fontSize: 14,
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		days: {
			color: '#959595',
			fontSize: 14,
			margin: 0,
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
		},
		benevitsContainer: {
			justifyContent: 'space-between',
			position: 'relative',
			marginTop: '2em',
			'&::after': {
				content: "''",
				display: 'flex',
				flexDirection: 'column',
				height: '370px',
				width: '100%',
				position: 'absolute',
				zIndex: '1',
				bottom: '0px',
				background:
					'linear-gradient(0deg, rgba(244,244,244,1) 45%, rgba(244,244,244,0.9360119047619048) 56%, rgba(244,244,244,0.8491771708683473) 63%, rgba(244,244,244,0.7791491596638656) 71%, rgba(244,244,244,0.5746673669467788) 78%, rgba(244,244,244,0.40379901960784315) 88%, rgba(252,252,252,0) 100%)',
				[theme.breakpoints.down('md')]: {
					height: '300px',
				},
			},
		},
		noPseudoClass: {
			position: 'relative',
			marginTop: '2em',
			'&::after': {
				content: "''",
				height: '0px',
				background: 'none',
			},
		},
		gridBenevit: {
			display: 'flex',
			'&.MuiGrid-root': {
				[theme.breakpoints.down('sm')]: {
					flexBasis: '50%',
					margin: '0 auto',
					paddingLeft: '0',
					paddingRight: '0'
				},
			},
			// Columna Izq
			'&:nth-child(2n+1)': {
				[theme.breakpoints.down('md')]: {
					paddingRight: '8px'
				},
			},
			// Columna Derecha
			'&:nth-child(2n+2)': {
				[theme.breakpoints.down('md')]: {
					paddingLeft: '8px'
				}
			},
		},

		benevitContainer: {
			display: 'flex',
			flex: '25% 1',
			width: '100%',
			height: 'auto',
			margin: '0 auto',
			padding: '50px 0px',
		},
		noBenevit: {
			background: '#E61737',
			fontSize: 14,
			[theme.breakpoints.down('sm')]: {
				fontSize: '9px !important',
			},
		},
		myBenevit: {
			background: '#71C65D !important',
			fontSize: 14,
			[theme.breakpoints.down('sm')]: {
				fontSize: '9px !important',
			},
		},
		buttonMore: {
			position: 'relative',
			bottom: '200px',
			left: '48%',
			border: 'none',
			height: '60px',
			width: '60px',
			padding: '0px',
			zIndex: '1',
			cursor: 'pointer',
			borderRadius: '50%',
			[theme.breakpoints.down('md')]: {
				height: '48px',
				width: '48px',
				bottom: '150px',
			},
			[theme.breakpoints.down('sm')]: {
				bottom: '80px',
				left: '44%',
			},
		},
		buttonImg: {
			width: '100%',
			borderRadius: '50%',
		},
		noButton: {
			display: 'none',
		},
		imgResponsive: {
			width: '50%',
		},
	})
);
