export const BRAND_FILTER_OPTIONS = [
  {
    id: 'todos',
    label: 'Todos',
    value: ''
  },
  {
    id: '0-9',
    label: '0 - 9',
    value: '0-9'
  },
  {
    id: 'A-D',
    label: 'A - D',
    value: 'A-D'
  },
  {
    id: 'E-G',
    label: 'E - G',
    value: 'E-G'
  },
  {
    id: 'H-K',
    label: 'H - K',
    value: 'H-K'
  },
  {
    id: 'L-O',
    label: 'L - O',
    value: 'L-O'
  },
  {
    id: 'P-S',
    label: 'P - S',
    value: 'P-S'
  },
  {
    id: 'T-W',
    label: 'T - W',
    value: 'T-W'
  },
  {
    id: 'X-Z',
    label: 'X - Z',
    value: 'X-Z'
  }
];