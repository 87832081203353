import React from 'react';
import { Article, Image, Content, Title, Action } from './styles';

const ArticleItem = ({data}:any) => {
  return (
    <Article href={data.link} target="_blank" rel="noopener noreferrer">
      <Image src={data.background} alt={data.title} />
      <Content>
        <Title>{data.title}</Title>
        <Action>{data.button_text + ' >'}</Action>
      </Content>
    </Article>
  );
}

export default ArticleItem;