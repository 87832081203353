import API from '..';
class CategoriesAPI extends API {
	categoriesFiltro(): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/categories`,
				method: 'GET',
				headers: {
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}

	categoriesFilter(slug: string): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v3/public/benevits/${slug}`,
				method: 'GET',
				headers: {
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
}
export const categoriesAPI = new CategoriesAPI('');
