import API from '..';
class ArticlesAPI extends API {
	articles(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v3/public/articles-recommendation`,
				method: 'GET',
			}),
			true
		);
	}
}
export const articlesAPI = new ArticlesAPI('');
