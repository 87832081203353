import { useState, useLayoutEffect, useEffect } from 'react';
import { textToCapitalLetter } from '../../utils/expressions';
import { useNavigate, NavLink } from 'react-router-dom';
import userIcon from '../../assets/img/user-icon.png';
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import Login from '../Login/Login';
import Register from '../Register/Register';
import NewsletterModify from '../Newsletter/NewsletterModify';
import styles from './NavBar.module.scss';
import { categoriesAPI } from '../../api/modules/categories';
import Button from '../Button';
import { getCategoryId } from '../../utils/searchFunctions';

import {
	Container,
	Nav,
	NavItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import MobileMenu from '../MobileMenu/MobileMenu';
import Searcher from '../Searcher/Searcher';
import BenevitPopUp from '../BenevitPopUp/BenevitPopUp';
import HandleGeo from '../HandleGeo/HandleGeo';

type categoriesFiltro = {
	id: number;
	name: string;
	icon_url: string;
};

const Navbar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);
	const [open, setOpen] = useState<boolean>(false);
	const [openLogin, setOpenLogin] = useState<boolean>(false); // IS NOT USED!!
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [modalNewsLetter, setModalNewsLetter] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectCatAList, setSelectedCatList] = useState<Array<categoriesFiltro>>([]);
	const [query, setQuery] = useState<string>('');
	const [isMobile, setIsMobile] = useState(false);


	const categoriesFunction = async () => {
		try {
			const res = await categoriesAPI.categoriesFiltro();
			const categories = res?.data;
			setSelectedCatList(categories);
		} catch (error) {

		}

	};

	const toggle = () => setIsOpen(!isOpen);

	const isOpenDrawer = () => {
		setModalNewsLetter(false);
		setOpen(!open);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	};

	const handleSearchClick = () => {
		navigate(`/resultados${query.length ? `?query=${query}` : ''}`);
	};

	const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			navigate(`/resultados${query.length ? `?query=${query}` : ''}`);
		}
	};

	const handleResize = () => {
		if (window.innerWidth < 1200) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useLayoutEffect(() => {
		function updateSize() {
			setOpen(false);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		categoriesFunction();
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{isMobile && <MobileMenu setModalNewsLetter={setModalNewsLetter} setOpenRegister={setOpenRegister} />}

			{!isMobile && (
				<nav className={styles.navbar}>
					{/* Top menu */}
					<div className={styles.navbarRow}>
						<Container fluid={false}>
							<div className={styles.topMenu}>
								<div className={styles.topMenuLeft}>
									<NavLink to="/">
										<img
											className={`img-fluid ${styles.logo}`}
											src="/assets/brand/socio-infonavit-color.svg"
											alt="infonavit"
										/>
									</NavLink>

									{/* Searcher */}
									<Searcher className={styles.search} />
								</div>
								<div className={styles.topMenuRight}>
									{/* If user is logged */}
									{user && (
										<div id="account" className={styles.userInfoContainer}>
											<div className={styles.userInfo}>
												<div className={styles.userInfoWelcome}>
													Bienvenido <span className={styles.userName}>{user.name ? textToCapitalLetter(user.name) : ''}</span>
												</div>

												<UncontrolledDropdown>
													<DropdownToggle nav caret className={styles.userInfoToggle}>
														Cuenta
													</DropdownToggle>
													<DropdownMenu end className={styles.userInfoDropdown}>
														<DropdownItem href="/mi-perfil" className={styles.option}>Mi perfil</DropdownItem>
														<DropdownItem onClick={() => { setModalNewsLetter(true) }} className={styles.option}>Preferencias Newsletter{' '}</DropdownItem>
														<DropdownItem onClick={() => { dispatch(actions.signOut()); navigate('/'); window.location.reload(); }} className={styles.option}>Cerrar Sesión</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
											<div>
												<img className={styles.avatar} src={user.avatar || userIcon} alt="" />
												{/*<UserInfo
													user={user}
													setModalNewsLetter={setModalNewsLetter}
												/>*/}
											</div>
										</div>
									)}

									{!user && (
										<Button
											id="dropdownSignIn"
											variant='solid'
											onClick={() => dispatch(actions.setIsOpenLogin(true))}
										>
											Ingresar o registrarse
											{/* <img className={styles.angleIcon} src="/assets/icons/angle-down-white.svg" alt="Dropdown icon" /> */}
										</Button>
									)}
								</div>
							</div>
						</Container>
					</div >

					{/* Bottom menu */}
					<div className={styles.navbarRow}>

						<Container>
							<div className={styles.bottomMenu}>
								<Nav className={styles.menu} navbar>
									<NavItem>
										<NavLink to="/" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="#DD0528" className="bi bi-house-door-fill" viewBox="0 0 16 16">
												<path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 
											.5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 
											0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
											</svg>
											Inicio
										</NavLink>
									</NavItem>
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav caret className={styles.menuItem}>
											Categorías
										</DropdownToggle>
										<DropdownMenu end className={styles.dropdown}>
											{selectCatAList.map((option, index) => (
												<DropdownItem key={option.id} href={`/resultados?categories=${option.id}`} className={styles.option}>
													<img className={styles.optionIcon} src={option?.icon_url} alt={option?.name} />
													{option.name}
												</DropdownItem>)
											)}
										</DropdownMenu>
									</UncontrolledDropdown>
									<NavItem>
										<NavLink to="/marcas" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Marcas</NavLink>
									</NavItem>
									<NavItem>
										{/* Url contain resultados?categories=9 */}
										<NavLink
											to={`/resultados?categories=${getCategoryId('Benevits relámpago', selectCatAList)}`}
											className={((window?.location?.href?.includes('resultados?categories=9') && !window?.location?.href?.includes('%2C') && !window?.location?.href?.includes('&') && !window?.location?.href?.includes(',')) ? `${styles.menuItemActive} active` : styles.menuItem)}
										>
											Benevits relámpago
										</NavLink>
									</NavItem>
									{user && (
										<NavItem id="navItemMisBenevits">
											<NavLink to="/mis-benevits" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Mis Benevits</NavLink>
										</NavItem>
									)}

									<NavItem className={`${styles.itemRight} d-flex`}>
										<NavLink to="/preguntas-frecuentes" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Preguntas frecuentes</NavLink>

										<NavLink to="/quieres-ser-aliado" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>¿Quieres ser aliado?</NavLink>

										{user !== null && (
											<NavLink to="/?tour=true" className={styles.menuItem}>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={18} height={18}><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM13 13.3551V14H11V12.5C11 11.9477 11.4477 11.5 12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.5855 14.4457 12.9248 13 13.3551Z"></path></svg>
												Tutorial
											</NavLink>
										)}
									</NavItem>
								</Nav>
							</div>
						</Container>
					</div>
				</nav >
			)}

			<Login />

			{openRegister && (
				<Register
					email=""
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
				/>
			)}

			<NewsletterModify modalNewsLetter={modalNewsLetter} setModalNewsLetter={setModalNewsLetter} />

			<BenevitPopUp />

			<HandleGeo />
		</>
	);
};

export default Navbar;
