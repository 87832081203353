import styled from '@emotion/styled';
import { textAlign } from '@mui/system';

export const DropdownContainer: any = styled('div')`
  position: relative;
`;

export const StyledDropdown: any = styled('div')`
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #DEE0E4;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  gap: 12px;
  overflow: hidden;
  
  .form-check {
    label {
      white-space: nowrap;
    }
  }
  
  ${(props: any) => props.open ? `display: flex;` : `display: none;`}
`;

export const StyledDropdownScroll: any = styled('div')`
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  flex: 1;
  background-color: #fff;
  padding: 10px 20px;
  `;

export const Icon: any = styled('img')`
  display: inline-flex;
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
  margin-left: 16px;
  margin-right: -16px;
  pointer-events: none;

  ${(props: any) => props.size === 'small' && `width: 12px; height: 12px;`}
  ${(props: any) => props.open ? `transform: rotateZ(180deg)` : ``}
`;

export const buttonStyles = {
  justifyContent: 'space-between',
  color: 'black',
  borderColor: 'black',
  fontSize: '16px'
};

export const counterStyles = {
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: '700',
  color: 'white',
  marginLeft: '8px',
  minWidth: '18px',
  width: "auot",
  height: '18px',
  padding: '0 6px',
  borderRadius: '18px',
  backgroundColor: 'black',
}