
import { Button, ButtonProps } from "@mui/material";
import Text, { TextProps } from "../../../Text/Text";
import { useStyles } from "./styles";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  textProps?: TextProps;
  buttonProps?: ButtonProps;
  children: string;
}

const VideoControlButton = ({ textProps, buttonProps, children }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Button
      className={`${classes.button} ${buttonProps?.className}`}
      {...buttonProps}
    >
      <Text
        color="#FFFFFF"
        fontSize={!isMobile ? 14 : 11}
        textAlign="center"
        {...textProps}
      >
        {children}
      </Text>
    </Button>
  )
};

export default VideoControlButton;