import React, { useEffect } from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../store/modules/session';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import Login from '../components/Login/Login';
import Home from '../pages/Home';

interface Props {
	component: React.ComponentType;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		div: {
			height: 80,
			[theme.breakpoints.down('sm')]: {
				height: 74,
			},
		},
	})
);

const ProtectedRoute = ({ component: RouteComponent }: Props) => {
	const dispatch = useDispatch();
	const { user, isLogged } = useSelector((state: RootState) => state.session);

	const userLogged = user && isLogged;

	useEffect(() => {
		if (!userLogged) dispatch(actions.setIsOpenLogin(true));
	}, []);

	if (userLogged) {
		return <RouteComponent />;
	}
	return <Home />;
};

export default ProtectedRoute;
