import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Benevits from '../../components/Benevits';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { filterBenevitAPI } from '../../api/modules/filtroBenevit';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import { Title } from './styles';

type benevit = {
  benevit: object;
};

const BenevitsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state.session);
  const token: string | undefined = user?.token;
  const [items, setItems] = useState<Array<benevit>>([]); // items from endpoint with params
  const [isLoading, setIsLoading] = useState(true);

  const _getItemsWithParams = async (query: URLSearchParams) => {
    setIsLoading(true);

    const res = await filterBenevitAPI.searchBenevits(query, token);

    const locked: any = [];
    const unlocked: any = [];

    //  If unlocked, push to unlocked array
    res.map((item: any) => {
      if (item.unlocked) {
        unlocked.push(item);
      }

      return item;
    });

    locked.sort((a, b) => b.id - a.id);
    locked.sort((a, b) => b.id - a.id);

    const allItems = [...unlocked];

    setIsLoading(false);

    setItems(allItems);
    return allItems;
  };

  // If searchParams change, get items from endpoint
  useEffect(() => {
    _getItemsWithParams(searchParams);
  }, [searchParams]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <Title>Mis Benevits</Title>
        </div>
      </div>

      <SearchFilters searchParams={searchParams} setIsLoading={setIsLoading} setSearchParams={setSearchParams} result={items} />

      {/* Benevits */}
      <div className="row pb-5">
        <div className="col-12">
          <Benevits
            benevits={items}
            isLoadingBenevits={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default BenevitsPage;
