import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const CustomPaper = styled(Paper)(({ theme }) => ({
	boxShadow: '0px 10px 27px #9D636317',
	borderRadius: 12,
	padding: 18,
	marginBottom: 24,
	marginTop: 16,
}));

export default CustomPaper;
