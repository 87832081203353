import React from 'react';
import styles from './Loader.module.scss'

const Loader = ({ isLoading, ...props }) => {

  if (!isLoading) return null;

  return (
    <span className={styles.loader} {...props}></span>
  );
};

export default Loader;