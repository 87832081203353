import { CardMedia } from '@mui/material';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Text from '../Text/Text';

type Props = {
	benevit: any;
	description: string;
	image?: string;
	src?: string;
	// Either image or src is required
} & (
		| {
			image: string;
		}
		| {
			src: string;
		}
	);

const BenevitCard = ({ benevit, image, src, description }: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const isLandscapeOrDown = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div className={classes.cardContainer}>
			<div
				className={classes.containerImage}
			>
				<img src={image} className={classes.imgBenevit} alt={description} />
			</div>
			<div className={classes.textDescription}>
				{description}
			</div>
		</div>
	);
};

export default BenevitCard;
