export const SORT_OPTIONS = [
  // {
  //   id: 'relevance',
  //   name: 'Relevancia',
  // },
  {
    id: 'expiration',
    name: 'Próximos a vencer',
    label: 'Próximos a vencer',
    value: 'expiration',
  }
]