import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  Cover,
  CoverImage,
  BrandLogo,
  Content,
  ContentText,
  Title,
  Discount,
  Properties,
  Category,
  CategoryIcon,
  CategoryLabel,
  Availability,
  ExpirationDate,
  Days,
  Actions
} from './styles';
import Button from '../Button';

interface IItemCard {
  handleSetBenevit?: any;
  data: any;
};

const ItemCardA = ({ data, handleSetBenevit }: IItemCard) => {
  const [isMobile, setIsMobile] = useState(false);
  const exp_days = moment(data.expiration_date).diff(moment(), 'days') + 1;
  const getExpirationDate = (days: number) => <ExpirationDate>Vence en <Days>{days}</Days> {days === 1 ? ' día' : 'días'}</ExpirationDate>;

  const renderCategories = (categories: any) => categories.map((category: any, index: number) => (
    <Category key={index}>
      {category?.icon_url && <CategoryIcon src={category?.icon_url} />}
      <CategoryLabel>
        {category?.name === 'Promociones relámpago' ? 'Benevits relámpago' : category?.name}
      </CategoryLabel>
    </Category>
  ));

  const _renderTerritores = (territories: any) => territories.map(
    (territory: any, index: number) => (<Availability key={index}>{territory?.name}</Availability>)
  );

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // On mount
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Card>
      <Cover background={data?.primary_color}>
        <CoverImage src={data?.vector_full_path} alt="random" size={data?.image_size} />
        <BrandLogo src={data?.ally?.mini_logo_full_path} alt="random" />
      </Cover>

      <Content>
        <ContentText>
          {/* Basic information */}
          <Title>{data.title}</Title>
          {data.id === 1000 && <span>Hasta agotar existencias</span>}

          {data?.discount && <Discount>{data?.discount}</Discount>}

        </ContentText>

        {/* Actions */}
        <Actions>
          <Button onClick={() => handleSetBenevit(data)} variant="solid" fluid={true} state={data?.unlocked ? 'success' : 'default'} className={`${data?.unlocked && 'clickUnlocketBenevit'}`}>
            {data?.unlocked === true ? data.id === 1000 ? 'Participando' : 'Ver Benevit' : 'Lo quiero'}
          </Button>
        </Actions>
      </Content>
    </Card>);
}

export default ItemCardA;