import API from '..';
import { isEmpty } from '../../utils/expressions';

class FlashPromotions extends API {
	search(query?: URLSearchParams, token?: string | undefined): Promise<any> {
		// Convert string to array
		const categoriesArray = query?.get('categories')?.split(',');
		const brandsArray = query?.get('brands')?.split(',');
		const statesArray = query?.get('states')?.split(',');
		const near = query?.get('near') ?? null;
		const queryParam = query?.get('query') ?? null;
		const order = query?.get('order') ?? null;

		const x = Math.floor(Math.random() * 500) + 1;

		const params = new URLSearchParams();
		if (categoriesArray && categoriesArray.length > 0) {
			categoriesArray.map((category) => params.append('categories[]', category));
		}

		if (brandsArray && brandsArray.length > 0) {
			brandsArray.map((brand) => params.append('brands[]', brand));
		}

		if (statesArray && statesArray.length > 0) {
			statesArray.map((state) => params.append('states[]', state));
		}

		if (near) params.append('near', near);

		if (queryParam) params.append('query', queryParam);

		if (order) params.append('order', order);

		return this.requestWrapper(
			this.request({
				url: `/v3/public/flash_deals${
					params !== undefined && params !== null && params.toString().length
						? `?${params}`
						: ''
				}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			false
		);
	}
}
export const flashPromotions = new FlashPromotions('');
