import * as React from 'react';
import { Box, Typography, Button } from '@mui/material';
import NotFoundImage from '../../assets/img/404-not-found.png';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(8),
			backgroundColor: theme.palette.common.white,
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		textDescription: {
			width: '80%',
			marginBottom: 20,
			[theme.breakpoints.up('md')]: {
				width: '40%',
			},
		},
		image: {
			width: 500,
			[theme.breakpoints.down('md')]: {
				width: 200,
			},
		},
		text: {
			textAlign: 'center',
		},
	})
);

const NotFound = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	return (
		<Box className={classes.root}>
			<div style={{ height: 80 }} />
			<Box className={classes.container}>
				<img className={classes.image} src={NotFoundImage} alt="Error 404" />
				<br></br>
				<Typography className={classes.text} variant="h4">
					<strong>Página no disponible</strong>
				</Typography>
				<br></br>
				<Box className={classes.textDescription}>
					<Typography className={classes.text} variant="subtitle2">
						La pagina que a la que estas intentando ingresar, no esta
						disponible por el momento, intenta ingresar mas tarde
					</Typography>
				</Box>
				<Button
					sx={{ textTransform: 'none', fontWeight: 700 }}
					startIcon={<ArrowBackIcon />}
					onClick={() => navigate('/')}
				>
					Regresar al inicio
				</Button>
			</Box>
		</Box>
	);
};

export default NotFound;
