import React, { useState, useEffect } from 'react';
import BuscarBenevits from '../../assets/img/buscar-benevits.png';
import { convertUrlParamsToFormValues } from '../../utils/searchFunctions';
import Button from '../Button';
import { useLocation, NavLink } from 'react-router-dom';
import styles from './WithoutBenevits.module.scss';

const WithoutBenevits = () => {
	const location = useLocation();
	const showButton = location.pathname === '/resultados' || location.pathname === '/promociones-relampago';

	return (
		<div className='col-12 my-6 d-flex flex-column align-items-center'>
			<img
				width={300}
				src={BuscarBenevits}
				alt="No se encontraron resultados"
				className='mb-4'
			/>
			<h4>
				<strong>No se encontraron resultados</strong>
			</h4>
			<p>Prueba con otra palabra o utiliza otros filtros</p>

			{showButton && <NavLink to="/resultados" className={styles.button}>Ver todos los Benevits</NavLink>}
		</div>
	);
};

export default WithoutBenevits;