import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import JSEncrypt from 'jsencrypt';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import styles from '../../components/Login/Login.module.scss';

const LoginUrlPage = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const LOGIN_PRIVATE_KEY = process.env.REACT_APP_LOGIN_PRIVATE_KEY || '';
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleDispatchLogin = () => {
    dispatch(actions.setIsLogin(true))
    // dispatch(actions.setIsOpenLogin(true))
  };

  const handleLogin = async () => {
    setIsLoading(true);
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(LOGIN_PRIVATE_KEY);
    const value = window.location.search.replace('?', '');

    const body = {
      credentials: value
    }

    try {
      const postData = await fetch('https://api.socioinfonavit.com/api/v3/member/sign-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      }).then(res => {
        interface IResponse {
          error: boolean;
          token: string;
          user: {
            email: string;
            role: string;
            id: number;
            temp_field: any;
            updated_at: string;
            verified: boolean;
            member: {
              avatar?: string;
              id?: number;
              id_socio_infonavit?: string;
              lastname?: string;
              lastname_mother?: string;
              mobile?: string;
              name?: string;
              only_name?: string;
              subscribed_to_newsletter?: boolean;
              tour?: boolean;
              user_id?: number;
              zipcode?: string | number | null;
            }
          }
        }
        // return IResponse
        return res.json() as Promise<IResponse>
      });

      if (!postData?.error) {
        dispatch(
          actions.setUser({
            email: postData.user.email,
            role: postData.user.role,
            token: `Bearer ${postData.token}`,
            name: postData.user.member.name,
            lastname: postData.user.member.lastname,
            lastname_mother: postData.user.member.lastname_mother,
            avatar: postData.user.member.avatar,
            id_socio_infonavit: postData.user.member.id_socio_infonavit,
            mobile: postData.user.member.mobile,
            subscribed_to_newsletter: postData.user.member.subscribed_to_newsletter,
            tour: postData.user.member.tour,
            user_id: postData.user.member.user_id,
          })
        );
        navigate('/');
      }

    }
    catch {
    }
    finally {
      setIsLoading(false);
    }
    // dispatch(actions.setIsLogin(true));
    // dispatch(actions.setUserKey(window.location.search.replace('?', '')));
  };


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleDispatchLogin();

      if (params.toString().length) {
        // If is login
        handleLogin();
        return;
      }

      dispatch(actions.setUserKey(null));
    }

    return () => {
      dispatch(actions.setIsLogin(false));
      dispatch(actions.setIsOpenLogin(false));
      dispatch(actions.setUserKey(null));
      isMounted = false;
    };
  }, []);

  return (
    <Modal show={true} style={{}} container={{ style: { maxWidth: 330, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } }}>
      <h2 className={styles.subTitle}>Iniciando sesión</h2>
      <Loader isLoading={true} />
    </Modal>
  );
};

export default LoginUrlPage;
