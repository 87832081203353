import { useRef, useEffect, useState } from 'react';
import { Link } from '@mui/material';
import close from '../../assets/img/buttom/close.svg';
import { nssValid } from '../../utils/expressions';
import { authAPI } from '../../api/modules/auth';
import { AlertColor } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { actions } from '../../store/modules/session';
import { IUser } from '../../interfaces/user';
import JSEncrypt from 'jsencrypt';
import FullWidthLoader from '../FullWidthLoader';
import NoCredential from '../NoCredential';
import Button from '../Button';
import styles from './Login.module.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../Modal/Modal';
import MaterialField from '../form/MaterialField/MaterialField';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

interface ICredentials {
	email: string;
	password: string;
}

interface IAlert {
	show: boolean;
	message: string;
	severity: AlertColor;
}

interface Props {
	email?: string;
}

const Login = ({
	email,
}: Props) => {
	const wrapperRef = useRef(null);
	const { isOpenLoginModal, isLogin, userKey } = useSelector((state: RootState) => state.session);
	const dispatch = useDispatch();
	const location = useLocation();
	const [openCred, setOpenCred] = useState<boolean>(false);
	const isLogged = new Event("isLogged");
	const navigate = useNavigate();
	const path = window.location.pathname.split('/')[1];
	// get url path 
	const pathname = location.pathname.substring(1).split('/')[0];
	const isABenevit = pathname === 'info-benevit';


	const [credentials, setCredentials] = useState<ICredentials>({
		email: '',
		password: '',
	});
	const [emailError, setEmailError] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		show: false,
		severity: 'success',
	});
	const [loader, setLoader] = useState(false);


	const setData = (name: string, value: string): void => {
		if (name === 'email') {
			let validate = { valid: false, msg: '' };
			const re = /^[0-9\b]+$/;
			if (value === '' || re.test(value)) {
				validate = nssValid(value);
				setEmailError(validate);
			} else {
				return;
			}
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const onSubmit = async (values): Promise<void> => {

		let base64_credentials = '';

		if (!userKey) {
			if (values && values?.password && values?.password.length < 1) {
				setAlert({
					show: true,
					message: 'Introduzca una contraseña válida.',
					severity: 'warning',
				});
				return;
			}
			const encrypt = new JSEncrypt();
			encrypt.setPublicKey(
				`-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuIGVY6DVBZ/X4rWIOC5B\nkwXupvlRDZibogBmdkoER0Z3WX8PtyWcpf09fcvjaBw6Xwcw73E6uQMWMbSYR/Q8\n/6lk7TjQ7bDBnJ5M2ZI3cXhVEth2sGPfdrTWwiDjmyTOCtUUrs7DkC1vwV/uWQNo\n/Ed2wTdY6VKk8Dnkg4yZwqfzwFzJ82dh8zh0l08UHP+35B5SqDkbi4x/xCf7Qgp4\ng7omgBLfxqsTAllWAs2Ra+1jn3xzr4gOdbNZpXuoCRfrcrE/EcXbTxaPqArXSzff\nVZqjR5ulv5o5dRPT4vu7f2RKAhheXfWjQ3fzmlrckBfQf2EC3aBUu4mZmnzMIYJt\nMQIDAQAB\n-----END PUBLIC KEY-----`
			);
			base64_credentials = encrypt.encrypt(
				values?.email + ':' + values?.password
			) as string;
		}

		if (userKey) {
			base64_credentials = userKey;
		}

		try {
			setLoader(true);
			authAPI
				.post<
					{ credentials: string },
					{
						data: {
							member: IUser;
							email: string;
							role: string;
							sign_in_count: any;
						};
						headers: { authorization: string };
					}
				>('', {
					credentials: base64_credentials,
				})
				.then((res) => {
					if (res.headers.authorization) {
						const { data, headers } = res;
						dispatch(
							actions.setUser({
								...data.member,
								email: data.email,
								role: data.role,
								token: headers.authorization,
								sign_in_count: data.sign_in_count,
							})
						);
						dispatch(actions.setUserKey(null));
						dispatch(actions.setIsLogin(false));
						dispatch(actions.setIsOpenLogin(false));
						dispatchEvent(isLogged);
						window.parent.postMessage({
							type: 'login',
							message: 'loginTrue',
						}, "*");

						if (isABenevit) {
							// reload page
							window.location.reload();
							return;
						}

						navigate('/');
					} else {
						setOpenCred(true);
					}
				})
				.catch((error) => {
					if (error?.description)
						setAlert({
							show: true,
							message:
								error?.description || 'Usuario o contraseña incorrectos',
							severity: 'error',
						});
					else setOpenCred(true);
				})
				.finally(() => {
					setLoader(false);
				});
		} catch (error) {
			setOpenCred(true);
		} finally {
			setAlert({
				message: '',
				show: false,
				severity: 'success',
			});
			// window.location.reload();
		}
	};

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.required('Requerido')
			.test('nss', 'El Número de Seguridad Social (NSS) debe tener 11 números', (value) => nssValid(value).valid),
		password: Yup.string().required('Requerido'),
	});

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (isOpenLoginModal && userKey) {
				onSubmit(null);
			}
		}

		return () => {
			isMounted = false;
		}
	}, [isOpenLoginModal, userKey]);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (path !== 'login') {
				dispatch(actions.setIsOpenLogin(false));
				dispatch(actions.setIsLogin(false));
				dispatch(actions.setUserKey(null));
			}
		}

		return () => {
			isMounted = false;
		}
	}, [path]);

	if (!isOpenLoginModal) return null;

	if (true) return (
		<>
			{!userKey && <FullWidthLoader open={loader} />}
			<NoCredential open={openCred} setOpen={setOpenCred} />
			<Modal
				show={isOpenLoginModal}
				setShow={() => dispatch(actions.setIsOpenLogin(false))}
				container={{ className: styles.container }}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { setSubmitting }) => onSubmit(values)}
				>
					{(formik) => {
						return (
							<Form onSubmit={formik.handleSubmit}>
								<div className={styles.container} ref={wrapperRef}>
									{!isLogin && (
										<img
											src={close}
											alt="cerrar"
											onClick={() => dispatch(actions.setIsOpenLogin(false))}
											className={styles.closeButton}
										/>
									)}
									<div className={styles.content}>
										{/* Header */}
										<div className={styles.header}>
											<p className={styles.title}>Bienvenido a Socio Infonavit </p>

											{(userKey) ? (
												<>
													<p className={styles.subTitle}>
														Iniciando sesión
													</p>
													<Loader isLoading={true} />
												</>
											) : (
												<>
													<Link
														target="_blank"
														href="https://portalmx.infonavit.org.mx/wps/wcm/connect/2454799c-d968-4e58-91ee-39592875e295/API_SI.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=oFcCnjw"
														style={{ textDecorationColor: '#8E8E8E' }}
													>
														<p className={styles.aviso}>
															Consulta nuestro Aviso de Privacidad
														</p>
													</Link>
													<p className={styles.subTitle}>
														Ingresa con tu Número de Seguridad Social (NSS) y
														contraseña de Mi cuenta Infonavit
													</p>
												</>
											)}
										</div>
										{(!userKey) && (
											<>
												<div className='col-12'>
													<MaterialField
														id="email"
														label="NSS (11 dígitos)"
														name="email"
														type="text"
														margin="normal"
														maxLength={11}
														className={styles.input}
														required
													/>

													<div className={styles.inputHelper}>
														{!isLogin && (
															<Link
																target="_blank"
																href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
																style={{ textDecoration: 'none' }}
																className={styles.passwordLost}
															>
																Consulta tu Número de Seguridad Social
															</Link>
														)}
													</div>

												</div>

												<div className='col-12'>
													<MaterialField
														id="password"
														label="Contraseña"
														name="password"
														type="password"
														className={styles.input}
														required
													/>

													<div className={styles.inputHelper}>
														{!isLogin && (
															<Link
																target="_blank"
																href="https://micuenta.infonavit.org.mx/cambio-contrasenia"
																style={{ textDecoration: 'none' }}
																className={styles.passwordLost}
															>
																Olvidé mi contraseña
															</Link>
														)}
													</div>

												</div>
											</>
										)}

										{alert.show && (
											<div className={styles.message}>
												<svg className={styles.alertIcon} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ReportProblemOutlinedIcon"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path></svg>
												<p>{alert.message}</p>
											</div>
										)}


										<div className={styles.divButton}>
											{!userKey && (
												<Button id="formLoginButtonSubmit" type='submit' variant='solid' className={styles.signin}>
													Entrar
												</Button>
											)}

											{!isLogin && (
												<div className={styles.signupContainer}>
													<p className={styles.text}>
														Si aún no estás registrado en Mi Cuenta Infonavit.
													</p>
													<Button
														id="formSignupButton"
														variant='outline'
														as="a"
														target="_blank"
														href="https://micuenta.infonavit.org.mx/registro"
														style={{ textDecoration: 'none' }}
														className={styles.signupButton}
													>
														Registrate aquí
													</Button>
												</div>
											)}
										</div>

									</div>
								</div>
							</Form>
						)
					}}
				</Formik>
			</Modal >
		</>);
};

export default Login;
