import Button from '../Button';
import { FilterContainer } from './styles';
import styles from './BrandFilters.module.scss';
import { BRAND_FILTER_OPTIONS } from '../../utils/brandFilterOptions';

const BrandFilters = ({ searchParams, setSearchParams, result, ...props }: any) => {

  const urlQuery = new URLSearchParams(window.location.search);

  const handleClick = (option: any) => {
    const urlQuery = new URLSearchParams(window.location.search);
    urlQuery.set('query', option.value);
    setSearchParams(urlQuery);
  };

  const _rederOptions = (options: any) => options.map((option: any) =>
    <Button
      variant="outline"
      key={option.value}
      onClick={() => handleClick(option)}
      className={`${urlQuery.get('query') === option?.value ? styles.optionActive : styles.option}`}
    >
      {option.label}
    </Button >
  );

  return (
    <FilterContainer>
      {_rederOptions(BRAND_FILTER_OPTIONS)}
    </FilterContainer>
  );
};

export default BrandFilters;