import { makeStyles } from '@mui/styles';
import { Theme, createTheme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerLoading: {
		display: 'flex',
		flexDirection: 'column',
		height: 200,
		alignItems: 'center',
		justifyContent: 'center',
	},
	textLoading: {
		color: '#e3e4e5',
		margin: 0,
	},
}));

export const themBackdrop = createTheme({
	components: {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: 99999,
					backgroundColor: 'rgba(0,0,0,0.6)',
				},
			},
		},
	},
});
