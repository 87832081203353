import styled from '@emotion/styled';
import { css } from '@emotion/react';

const solid = css`
  --bg-color: #DD0528;
  --hover-bg-color: #85031A;
  --disabled-bg-color: #999999;
  --color: white;
  border: 0;
`;

const outline = css`
  --bg-color: transparent;
  --color: #000;
  border: 1px solid var(--color);

  &:hover {
    --color: #000000;
  }

  &:disabled {
    --color: #999999;
  }
`;

const squareSolid = css`
  --bg-color: #DD052B;
  --color: white;
  border: 0;
  border-radius: 5px;
`;

const squareOutline = css`
  --bg-color: transparent;
  --color: #DD0528;
  border: 1px solid var(--color);
  border-radius: 5px;
`;

export const StyledButton: any = styled('button')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color);
  border-radius: 40px;
  cursor: pointer;
  background-color: var(--bg-color);
  text-decoration: none;

  &:hover {
    ${(props: any) => props.variant !== 'solid' && css`
        filter: brightness(0.9);
    `}
    ${(props: any) => props.variant === 'solid' && css`
      background-color: var(--hover-bg-color);
    `}
  }

  &:active {
    filter: brightness(0.8);
  }

  &:disabled {
     ${(props: any) => props.variant !== 'solid' && css`
        filter: brightness(0.5);
    `}
    ${(props: any) => props.variant === 'solid' && css`
      background-color: var(--disabled-bg-color);
    `}
    cursor: not-allowed;
  }

  ${(props: any) => props.variant === 'solid' && solid}
  ${(props: any) => props.variant === 'outline' && outline}
  ${(props: any) => props.variant === 'squareSolid' && squareSolid}
  ${(props: any) => props.variant === 'squareOutline' && squareOutline}
  ${(props: any) => props.fluid && css`width: 100%;`}
  ${(props: any) => props.size === 'small' && css`height: 32px;`}

  ${(props: any) => props.state === 'success' && css`
    --bg-color: #1D8CE3;
    --hover-bg-color: #146fb6;
    --disabled-bg-color: #999999;
    --color: white;
  `}
`;