import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useStyles } from './styles';

type Props = {
	children: React.ReactChild;
};

const Layout = ({ children }: Props) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<CssBaseline />
			<Navbar />
			<Container className={classes.root} maxWidth={false} disableGutters={true}>
				{children}
			</Container>
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
