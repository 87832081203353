import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { benevitsAPI } from '../../api/modules/benevits';
import BenevitInformationArrangement from '../../components/BenevitInformationArrangement/BenevitInformationArrangement';
import Benevits from '../../components/Benevits';
import { RootState } from '../../store';
import { unlocked, unlockedText } from './styles';
import VideoPlaybackModal from '../../components/VideoPlaybackModal/VideoPlaybackModal';
import QuestionsAndAnswersModal from '../../components/QuestionsAndAnswersModal/QuestionsAndAnswersModal';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import qAndAImage from '../../assets/img/actividades-a-financiar.png';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container } from 'reactstrap';
import ModalLoading from '../../components/ModalLoading';

export const BenevitInformation = () => {
	const [benevit, setBenevit] = useState<any>({ name: '' });
	const [cupon, setCupon] = useState('');
	const [isLoadingBenevit, setIsLoadingBenevit] = useState(false);
	const [isLoadingBenevits, setIsLoadingBenevits] = useState<boolean>(false);
	const [benevits, setBenevits] = useState([]);
	const { user } = useSelector((state: RootState) => state.session);
	const token: string | undefined = user?.token;
	const navigate = useNavigate();
	const params = useParams();
	const theme = useTheme();
	const [campaingModal, setCampaingModal] = useState<boolean>(false);
	const [campaingModalVideo, setCampaingModalVideo] = useState<boolean>(false);
	const [campaing, setCampaing] = useState<any>();
	const [b, setB] = useState<any>();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const onCloseModalCampaing = (): void => navigate('/');

	useEffect(() => {
		handleGetInfo();
	}, [params.id]);

	const getBenevitWithStatus = (benevitArr: object[], benevitLockedStatus: boolean) => {
		let newBenevitArr: any = [];
		benevitArr.forEach((benevitObj: any) => {
			newBenevitArr.push({ ...benevitObj, benevitLockedStatus });
		});

		return newBenevitArr;
	};
	const handleUnlockBenevit = async (answers?: any[], slide?: any) => {
		await benevitsAPI.unlockBenevit(b.id, token);
		if (campaingModalVideo) {
			await benevitsAPI.updateMetrics(campaing?.metrics?.id as number, token);
			setCampaingModalVideo(false);
		} else {
			await benevitsAPI.updateAnsweredMetrics(
				campaing?.metrics?.id as number,
				token
			);
			setCampaingModal(!campaingModal);
			if ((answers?.length ?? 0) > 0) {
				/* aplicar logica para guardar las respuestas */
				answers?.forEach((answer, index) => {
					benevitsAPI.postAnswers(
						campaing?.questions[index].id as number,
						answer.option_value,
						token
					);
				});
			}
		}
		handleGetInfo();
	};

	const handleGetInfo = async (loading?: boolean) => {
		setIsLoadingBenevit(true);

		// Ge4t Benevit by id
		await benevitsAPI.getBenevit(params?.id || '', token).then(async (response) => {

			if (response.data) {
				if (loading !== false) setIsLoadingBenevits(true);

				// Get "Descubre más Benevits" from "Benevits API"
				await benevitsAPI.getRandomBenevits(token).then(async (res) => {
					let allBenevitsArray: any = [
						...getBenevitWithStatus(res?.data, true),
					];

					setBenevits(allBenevitsArray);
				});

				if (response?.data?.lockedBenevit) {
					setB(response?.data?.lockedBenevit);
					await benevitsAPI.getQuestion(token).then(async (response) => {
						let data = { ...response.data };
						const questSort = (data.questions || []).sort(
							(item1: any, item2: any) => {
								if (item1.question_index > item2.question_index) {
									return 1;
								}
								if (item1.question_index < item2.question_index) {
									return -1;
								}
								return 0;
							}
						);
						setCampaing({ ...data, questions: [...questSort] });
						if (response.data.campaign_type === 'video') {
							setCampaingModalVideo(!campaingModalVideo);
						} else {
							setCampaingModal(!campaingModal);
						}
					}).catch((error) => {
						console.log('error', error)
					}).finally(() => {
						setIsLoadingBenevit(false);
					});
				} else {
					setBenevit(response?.data?.benevit);
					setCupon(response?.data?.coupon?.code);
				}

				setIsLoadingBenevits(false);
			}
			setIsLoadingBenevit(false);
		});
	};

	if (isLoadingBenevit) return <ModalLoading loading={true} />;

	if (campaingModalVideo) {
		return (
			<VideoPlaybackModal
				isMobileVideo={isMobile}
				open={campaingModalVideo}
				url={campaing}
				// url={campaing?.content}
				campaingURL={campaing?.url}
				onCancel={() => navigate('/')}
				handleUnlockBenevit={handleUnlockBenevit}
				light={true}
			// onMoreInfoClick={handleMoreInfo}
			/>
		);
	}

	if (campaingModal) {
		return (
			<QuestionsAndAnswersModal
				open={campaingModal}
				onBackButton={onCloseModalCampaing}
				questionImage={qAndAImage}
				question={campaing}
				handleUnlockBenevit={handleUnlockBenevit}
			/>
		);
	}


	return (
		<>
			<Container>
				{(benevit?.name.length > 0) && (
					<Breadcrumbs data={[
						{
							name: 'Mis Benevits',
							link: '/mis-benevits',
						},
						{
							name: benevit?.categories[0]?.name,
							link: `/mis-benevits?categories=${benevit?.categories[0]?.id}`,
						},
						{
							name: benevit?.ally?.name,
							link: `/mis-benevits?brands=${benevit?.ally?.id}`,
						},
						{
							name: benevit?.name,
							link: ''
						}
					]} />
				)}

				<div style={unlocked}>
					{/* <img src='/assets/icons/lock-unlock-line.svg' alt='unlock' width={24} height={24} /> */}
					<img src='/assets/icons/sparkling-line.svg' alt='unlock' width={24} height={24} />
					<p style={unlockedText}>Has obtenido el siguiente Benevit</p>
				</div>

				<BenevitInformationArrangement
					benevit={benevit}
					isLoadingBenevit={isLoadingBenevit}
					discountCode={cupon}
					handleGetInfo={handleGetInfo}
				/>

				<div
					className='mb-5'
					style={{
						width: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{benevits.length !== 0 && (
						<Benevits
							title="Descubre más Benevits"
							sizeTitle={18}
							alignTitle="left"
							benevits={benevits}
							isLoadingBenevits={isLoadingBenevits}
						/>
					)}
				</div>
			</Container >

		</>
	);
};
