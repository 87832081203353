import './style.css';  
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import logoApp from '../../assets/img/icon_si.png';
import appStore from '../../assets/img/apple.png';
import googlePlay from '../../assets/img/google.png';
import appGallery from '../../assets/img/huawei.png';



const BenevitAction = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	width: '160px',
	fontSize: '14px',
	color: theme.palette.common.white,
}));
const DownloadApp = () => {
    const navigate = useNavigate();
    const handleHome = () =>{
        navigate('/')
    }
	return (
		<> 
            <div className="div" />
            <div className="containerData">
                <img src={logoApp}/>
                <h1 className='title'>Descarga la app o continúa en la página</h1>
                <div className="divStores">
                        <a id='descargaAppiOS' href="https://apps.apple.com/mx/app/socio-infonavit-app/id6447075538" target='_blank'><img src={appStore}/></a> 
                        <a id='descargaAppAndroid' href="https://play.google.com/store/apps/details?id=mx.infonavit.socio"  target='_blank'><img src={googlePlay}/></a> 
                        <a id='descargaAppHuawei' href="https://appgallery.huawei.com/app/C107975941"  target='_blank'><img src={appGallery}/></a>
                </div>
                <BenevitAction
                        id='btnHome'
						variant="contained"
						disableElevation 
                        onClick={handleHome}>Continúa en web</BenevitAction>
            </div>
            
		</>
	);
};

export default DownloadApp;