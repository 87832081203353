import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const CustomButton = styled(Button)({
	textTransform: 'none',
	fontSize: 14,
	padding: '6px 12px',
	backgroundColor: '#fff',
	borderColor: '#00000029',
	color: '#000',
	fontWeight: 700,
});
