import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from './styles';

interface MediaProps {
	loading?: boolean;
}

function SkeletonBody() {
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item md={6} xs={12}>
				<Box sx={{ p: 5 }}>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton variant="rectangular" height={220} />
					</Box>
					<Box sx={{ pt: 1.5 }}>
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</Box>
				</Box>
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Box sx={{ p: 5 }}>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton />
					</Box>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton />
					</Box>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton />
					</Box>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton />
					</Box>
					<Box sx={{ pt: 0.5 }}>
						<Skeleton />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}

export default function CustomSkeleton() {
	return <SkeletonBody />;
}
