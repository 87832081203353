import thunk from 'redux-thunk';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore, applyMiddleware, compose, Store } from 'redux';
import session from './modules/session';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

export const mainReducer = combineReducers({ session });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer({ key: 'root', storage }, mainReducer);

export const storeFactory = (
	initialState = {}
): { store: Store; persistor: Persistor } => {
	const store = createStore(
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(thunk))
	);
	const persistor = persistStore(store);
	composeEnhancers(applyMiddleware(thunk));
	return { store, persistor };
};

export default storeFactory();

export type RootState = ReturnType<typeof mainReducer>;
