import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: 'calc(80vh - 300px)',
		},
	})
);
