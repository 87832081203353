import styled from "@emotion/styled";

export const StyledCategories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const CategoriesRow = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  width: 100%;

  > .category:not(:last-child) {
    border-right: 1px solid #EBEBEB;
  }

  @media (max-width: 1400px) {
    overflow-x: scroll;
  }
`;