import React from 'react';
import { Divider, Grid, Link, Typography, List, ListItem } from '@mui/material';
import { useStyles } from './styles';
import facebook from '../../assets/img/facebook.png';
import linkedin from '../../assets/img/linkedin.png';
import youtube from '../../assets/img/youtube.png';
import twitter from '../../assets/img/x.svg';
import spotify from '../../assets/img/spotify.png';
import Button from '../../components/Button';
import huaweiAppGallery from '../../assets/img/huawei-app-gallery.png';
import googlePlay from '../../assets/img/google-play.png';
import appStore from '../../assets/img/app-store.png';
import sipot from '../../assets/img/socio-infonavit-1.png';
import pnt from '../../assets/img/socio-infonavit-2.png';
import styles from './Footer.module.scss';
import iconHuawei from '../../assets/icons/huawei.png';
import iconIos from '../../assets/icons/ios.png';
import iconAndroid from '../../assets/icons/android.png';


interface SocialMedia {
	id: number;
	icon: string;
	title: string;
	url: string;
}

const SOCIALMEDIA: SocialMedia[] = [
	{
		id: 1,
		icon: facebook,
		title: 'Facebook',
		url: 'https://www.facebook.com/ComunidadInfonavit',
	},
	{
		id: 2,
		icon: linkedin,
		title: 'Linkedin',
		url: 'https://www.linkedin.com/company/infonavit/?originalSubdomain=mx',
	},
	{
		id: 3,
		icon: youtube,
		title: 'Youtube',
		url: 'https://www.youtube.com/user/ComunidadInfonavit',
	},
	{
		id: 4,
		icon: spotify,
		title: 'Spotify',
		url: 'https://open.spotify.com/show/3gUlQtXksmtoyEQvcxdVfH?si=70aa82859be44161&nd=1',
	},
	{
		id: 5,
		icon: twitter,
		title: 'Twitter',
		url: 'https://twitter.com/Infonavit',
	},
];

const APPS: SocialMedia[] = [
	{
		id: 1,
		icon: appStore,
		title: 'Apple Store',
		url: 'https://apps.apple.com/mx/app/socio-infonavit-app/id6447075538',
	},
	{
		id: 2,
		icon: googlePlay,
		title: 'Play Store',
		url: 'https://play.google.com/store/apps/details?id=mx.infonavit.socio',
	},
	{
		id: 3,
		icon: huaweiAppGallery,
		title: 'Huawei Store',
		url: 'https://appgallery.huawei.com/app/C107975941',
	},
];

type PropsSocialMedia = {
	title: string;
	iconsList: SocialMedia[];
	className?: string;
};

const SocialMediaSection = ({ title, iconsList, className }: PropsSocialMedia) => {
	const classes = useStyles();
	return (
		<div className={`${classes.socialMediaSectionRoot} ${className}`}>
			<Typography style={{ fontSize: 14, fontWeight: 500 }}>{title}</Typography>
			<List className={classes.list}>
				{iconsList.map((sm: SocialMedia) => (
					<ListItem
						sx={(theme) => ({
							[theme.breakpoints.down('md')]: {
								justifyContent: 'center',
							},
						})}
						key={sm.id}
						disablePadding
					>
						<Link target="_blank" rel="noopener" href={sm.url}>
							<img
								src={sm.icon}
								width={
									sm.title === 'Facebook'
										? '10'
										: sm.title === 'Linkedin'
											? '18'
											: '20'
								}
								alt="icon"
							/>
						</Link>
					</ListItem>
				))}
			</List>
		</div>
	);
};

const Infonatel = () => {
	const classes = useStyles();
	return (
		<div className={styles.informationContainer}>
			<Typography sx={{ color: '#666666', fontWeight: 500 }}>Infonatel</Typography>
			<div className={`${classes.divInformation} ${styles.information}`}>
				<Grid item md={10} className={classes.gridItem}>
					<Link href="tel:5591715050" underline="none" color={'#506278'}>
						<Typography variant="h6" sx={{ marginTop: 2, fontWeight: 400 }}>
							55 9171 5050
						</Typography>
					</Link>
					<Typography variant="body2">Ciudad de México</Typography>
				</Grid>
				{/* <Grid item md={10}> */}
				{/* </Grid> */}
				<Grid item md={10} className={classes.gridItem}>
					<Link href="tel:8000083900" underline="none" color={'#506278'}>
						<Typography variant="h6" sx={{ marginTop: 2, fontWeight: 400 }}>
							800 008 3900
						</Typography>
					</Link>
					<Typography variant="body2">
						Desde cualquier parte del país
					</Typography>
				</Grid>
				{/* <Grid item md={12}> */}
				{/* </Grid> */}
			</div>
		</div>
	);
};

const FooterMainSection = () => {
	const classes = useStyles();
	return (
		<>
			<Grid item xs={12} sm={4} className={classes.gridSocial}>
				<SocialMediaSection title={'Síguenos'} iconsList={SOCIALMEDIA} />
				<SocialMediaSection title={'Descarga la aplicación'} iconsList={APPS} />
			</Grid>
			<Grid item xs={12} sm={0.5}></Grid>
			<Grid item xs={12} sm={3}>
				<Infonatel />
			</Grid>
			<Grid item xs={12} sm={4.5} className={classes.gridButton}>
				<div className="row">
					<div className="col-6 col-md-12 d-flex justify-content-center">
						<Button
							label="Mi Cuenta Infonavit"
							onClick={() =>
								window.open('https://micuenta.infonavit.org.mx/', '_blank')
							}
							variant="solid"
							className={styles.button}
							style={{ marginBottom: 20 }}
						/>
					</div>
					<div className="col-6 col-md-12 d-flex justify-content-center">
						<Button
							label="Infonavit Fácil"
							onClick={() =>
								window.open('https://infonavitfacil.mx/', '_blank')
							}
							variant="solid"
							className={styles.button}
						/>
					</div>
				</div>

				<Grid container className={classes.gridAlign}>
					<Grid className={classes.alignCenter} item xs={12} md={6}>
						<img
							className={classes.image}
							src={sipot}
							style={{ cursor: 'pointer' }}
							onClick={() =>
								window.open(
									'https://portalmx.infonavit.org.mx/',
									'_blank'
								)
							}
							alt="Consulta nuestras obligaciones de transparencia"
						/>
					</Grid>
					<Grid className={classes.alignCenter} item xs={12} md={6}>
						<img
							className={classes.image}
							src={pnt}
							style={{ cursor: 'pointer' }}
							onClick={() =>
								window.open(
									'https://consultapublicamx.inai.org.mx/vut-web/faces/view/consultaPublica.xhtml#inicio',
									'_blank'
								)
							}
							alt="Consulta nuestras obligaciones de transparencia"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const CopyrightSection = () => {
	const classes = useStyles();
	return (
		<>
			<Divider
				sx={{
					marginBottom: (theme) => theme.spacing(4),
					marginTop: (theme) => theme.spacing(0),
				}}
				className={classes.divider}
			/>
			<div className={classes.divLinks}>
				<Grid item xs={12} md={3}>
					<Typography style={{ fontSize: 12, fontWeight: 500 }} variant="body1">
						Derechos reservados &copy; Infonavit {new Date().getFullYear()}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Link
						href="https://portalmx.infonavit.org.mx/wps/wcm/connect/2454799c-d968-4e58-91ee-39592875e295/API_SI.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=oFcCnjw"
						target="_blank"
						rel="noopener"
						color="inherit"
						variant="body1"
						style={{ fontSize: 12, fontWeight: 500 }}
					>
						Protección de datos personales
					</Link>
				</Grid>
				<Grid item xs={12} md={4}>
					<Link
						href="https://portalmx.infonavit.org.mx/wps/wcm/connect/eca2a7b7-eeb3-4fec-a4d0-cd49f219503c/terminos_condiciones_socio_Infonavit.pdf?MOD=AJPERES"
						target="_blank"
						rel="noopener"
						color="inherit"
						variant="body1"
						style={{ fontSize: 12, fontWeight: 500 }}
					>
						Términos y condiciones
					</Link>
				</Grid>

			</div>
			<Grid item xs={12} md={2}></Grid>
		</>
	);
};

const Footer = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<div className='container'>
				<div className='row'>
					<FooterMainSection />
					<CopyrightSection />
				</div>
			</div>
		</Grid>
	);
};

export default Footer;
