export const REPUBLIC_STATES = [
	// { id: '0', name: 'Ver todos', code: "X" },
	{ id: '34', value: '34', name: 'Disponibles en todo el país', code: "XX" },
	{ id: '1', value: '1', name: 'Disponibles en todo el país', code: "XX" },
	{ id: '2', value: '2', name: 'Aguascalientes', code: 'Aguascalientes' },
	{ id: '3', value: '3', name: 'Baja California', code: 'Baja California' },
	{ id: '4', value: '4', name: 'Baja California Sur', code: 'Baja California Sur' },
	{ id: '5', value: '5', name: 'Campeche', code: 'Campeche' },
	{ id: '10', value: '10', name: 'CDMX', code: 'Ciudad de México' },
	{ id: '9', value: '9', name: 'Chihuahua', code: 'Chihuahua' },
	{ id: '8', value: '8', name: 'Chiapas', code: 'Chiapas' },
	{ id: '6', value: '6', name: 'Coahuila', code: 'Coahuila de Zaragoza' },
	{ id: '7', value: '7', name: 'Colima', code: 'Colima' },
	{ id: '11', value: '11', name: 'Durango', code: 'Durango' },
	{ id: '13', value: '13', name: 'Guerrero', code: 'Guerrero' },
	{ id: '12', value: '12', name: 'Guanajuato', code: 'Guanajuato' },
	{ id: '14', value: '14', name: 'Hidalgo', code: 'Hidalgo' },
	{ id: '15', value: '15', name: 'Jalisco', code: 'Jalisco' },
	{ id: '16', value: '16', name: 'Estado de México', code: 'México' },
	{ id: '17', value: '17', name: 'Michoacán', code: 'Michoacán de Ocampo' },
	{ id: '18', value: '18', name: 'Morelos', code: 'Morelos' },
	{ id: '19', value: '19', name: 'Nayarit', code: 'Nayarit' },
	{ id: '20', value: '20', name: 'Nuevo León', code: 'Nuevo León' },
	{ id: '21', value: '21', name: 'Oaxaca', code: 'Oaxaca' },
	{ id: '22', value: '22', name: 'Puebla', code: 'Puebla' },
	{ id: '24', value: '24', name: 'Quintana Roo', code: 'Quintana Roo' },
	{ id: '23', value: '23', name: 'Querétaro', code: 'Querétaro' },
	{ id: '26', value: '26', name: 'Sinaloa', code: 'Sinaloa' },
	{ id: '25', value: '25', name: 'San Luis Potosí', code: 'San Luis Potosí' },
	{ id: '27', value: '27', name: 'Sonora', code: 'Sonora' },
	{ id: '28', value: '28', name: 'Tabasco', code: 'Tabasco' },
	{ id: '29', value: '29', name: 'Tamaulipas', code: 'Tamaulipas' },
	{ id: '30', value: '30', name: 'Tlaxcala', code: 'Tlaxcala' },
	{ id: '31', value: '31', name: 'Veracruz', code: 'Veracruz de Ignacio de la Llave' },
	{ id: '32', value: '32', name: 'Yucatán', code: 'Yucatán' },
	{ id: '33', value: '33', name: 'Zacatecas', code: 'Zacatecas' },
];
