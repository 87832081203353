import {
	INITIAL_STATE,
	SET_USER,
	UPDATE_USER,
	SIGN_OUT,
	SIGN_IN,
	CHANGE_REMEMBER,
	IS_OPEN_LOGIN,
	IS_OPEN_NEWSLETTER,
	SesionState,
	SignInProps,
	IS_LOGIN,
	USER_KEY,
} from '../types/session';
import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IUser, ILogin } from '../../interfaces/user';
import { set } from 'date-fns';

export const actions = {
	setUser: createAsyncThunk(SET_USER, (user: Partial<IUser>) => {
		// localStorage.setItem('tk', user.token);
		return user;
	}),
	updateUser: createAsyncThunk(UPDATE_USER, (user: IUser) => {
		// localStorage.setItem('tk', user.token);
		return user;
	}),
	signIn: createAsyncThunk(SIGN_IN, (props: SignInProps) => {}),
	signOut: createAsyncThunk(SIGN_OUT, () => {
		// localStorage.clear();
		return Promise.resolve();
	}),
	changeRemember: createAsyncThunk(CHANGE_REMEMBER, (value: number) => {
		return value;
	}),
	setIsOpenLogin: createAsyncThunk(IS_OPEN_LOGIN, (value: boolean) => value),
	setIsOpenNewsletter: createAsyncThunk(IS_OPEN_NEWSLETTER, () => {}),
	setNotOpenNewsletter: createAsyncThunk(IS_OPEN_NEWSLETTER, () => {}),
	setIsLogin: createAsyncThunk(IS_LOGIN, (value: boolean) => value),
	setUserKey: createAsyncThunk(USER_KEY, (value: string | null) => value),
};

export default createReducer(INITIAL_STATE, {
	[actions.setUser.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => ({
		..._state,
		user: action.payload,
		isLogged: true,
		isOpenNewsletterModal: false,
	}),
	[actions.updateUser.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => ({
		..._state,
		user: action.payload,
		isLogged: true,
		isOpenNewsletterModal: true,
	}),
	[actions.signIn.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			..._state,
			user: action.payload,
			isLogged: true,
			isOpenNewsletterModal: true,
		};
	},
	[actions.setIsOpenNewsletter.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			..._state,
			isOpenNewsletterModal: true,
		};
	},
	[actions.setNotOpenNewsletter.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			..._state,
			isOpenNewsletterModal: false,
		};
	},
	[actions.setIsOpenLogin.fulfilled.type]: (
		_state: SesionState,
		action: any
	): SesionState => {
		return {
			..._state,
			isOpenLoginModal: action.payload,
		};
	},
	[actions.setIsLogin.fulfilled.type]: (
		_state: SesionState,
		action: any
	): SesionState => {
		return {
			..._state,
			isLogin: action.payload,
		};
	},
	[actions.changeRemember.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<number>
	): SesionState => {
		return {
			..._state,
		};
	},
	[actions.signIn.rejected.type]: (state: SesionState): SesionState => {
		return state;
	},
	[actions.signOut.fulfilled.type]: (_state: SesionState): SesionState => ({
		..._state,
		user: null,
		isLogged: false,
		isOpenNewsletterModal: false,
	}),
	[actions.setUserKey.fulfilled.type]: (
		_state: SesionState,
		action: any
	): SesionState => ({
		..._state,
		userKey: action.payload,
	}),
});
