import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Benevits from '../../components/Benevits';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { filterBenevitAPI } from '../../api/modules/filtroBenevit';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Title } from '../../components/SearchBanner/styles';

type benevit = {
  benevit: object;
};

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state.session);
  const token: string | undefined = user?.token;
  const [items, setItems] = useState<Array<benevit>>([]); // items from endpoint with params
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(0);

  const _getItemsWithParams = async (query: URLSearchParams, changeState: boolean = true, randomize: boolean = false) => {
    setIsLoading(true);
    const hasOrderParam = query.get('order') ? true : false;

    const res = await filterBenevitAPI.searchBenevits(query, token);

    const locked: any = [];
    const unlocked: any = [];

    res.map((item: any) => {
      if (item.unlocked) {
        unlocked.push(item);
      } else {
        locked.push(item);
      }
      return item;
    });

    if (!randomize) {
      locked.sort((a, b) => b.id - a.id);
      locked.sort((a, b) => b.id - a.id);
    }

    const allItems = [...locked, ...unlocked];

    setIsLoading(false);

    // Is there an order param?
    if (hasOrderParam) {
      setItems(res);
      if (changeState) setNoResults((count: number) => ((res.length === 0) ? count + 1 : 0));
      return res;
    }

    setItems(allItems);
    if (changeState) setNoResults((count: number) => ((res.length === 0) ? count + 1 : 0));
    return allItems;
  };

  // If searchParams change, get items from endpoint
  useEffect(() => {
    _getItemsWithParams(searchParams);
  }, [searchParams]);

  // Get items from endpoint if no results
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (noResults > 0) {
        const urlParams = new URLSearchParams('');
        _getItemsWithParams(urlParams, false, true);
      }
    }

    return () => { isMounted = false };
  }, [noResults]);

  return (
    <div className="container">
      <Breadcrumbs />

      <SearchFilters searchParams={searchParams} setSearchParams={setSearchParams} isLoading={isLoading} setIsLoading={setIsLoading} result={items} noResults={noResults} />

      {/* Benevits */}
      <div className="row pb-5">
        {noResults > 0 && (
          <div className="col-12 mb-3">
            <Title>Te podría interesar</Title>
          </div>
        )}
        <div className='col-12'>
        </div>
        <div className="col-12">
          <Benevits
            benevits={items}
            isLoadingBenevits={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
