import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Slider from 'react-slick';
import prev from '../../assets/img/buttom/prevA.png';
import next from '../../assets/img/buttom/nextA.png';
import {
	Slide,
	SlideImage,
	SlideContent,
	Heading,
	Description,
	Arrow,
	ArrowIcon,
} from './styles';
import { carouselAPI } from '../../api/modules/carousel';
import './styles.css';
import { actions } from '../../store/modules/session';
import { useNavigate } from 'react-router-dom';

type bannerObj = {
	active: true | false;
	background: null | string;
	background_movile?: string;
	button_text: string;
	description: null | string;
	has_buttom: null | boolean;
	id: number;
	link: string;
	name: null | string;
	position: number;
	title: null | string;
};

const CustomArrow = (props: any) => {
	const { onClick } = props;

	return (
		<Arrow direction={props.direction}>
			<ArrowIcon src={props.arrow} onClick={onClick} />
		</Arrow>
	);
};

const FullwidthCarousel: any = (props: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.session);
	const [banners, setBanners] = useState<Array<bannerObj>>([]);
	const [isMobile, setIsMobile] = useState(false);
	const [autoplaySpeed, setAutoplaySpeed] = useState(2000);

	const settings = {
		adaptiveHeight: true,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <CustomArrow arrow={next} direction="next" />,
		prevArrow: <CustomArrow arrow={prev} direction="prev" />,
		autoplay: true,
		autoplaySpeed: autoplaySpeed,
		appendDots: (dots: any) => <ul className="banner-dots">{dots}</ul>,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
				}
			},
		]
	};

	const staticBanners = [{
		id: 'signup',
		background: '/assets/images/banner/signup.png',
		background_movile: '/assets/images/banner/signup-mobile.png',
		has_buttom: true,
		onClick: () => dispatch(actions.setIsOpenLogin(true)),
	}];

	const bannerFunction = async () => {
		const res = await carouselAPI.banners();
		const banner = res.data.result;
		setBanners(banner);
	};

	const fetchConfig = async () => {
		const res = await carouselAPI.config();
		// conver string to number
		const toNumber = (value: string) => {
			const number = Number(value);
			return isNaN(number) ? 0 : number;
		}

		setAutoplaySpeed(toNumber(res?.data?.config?.autoplaySpeed));
	};

	// Get banner onClick
	const getBannerOnClick = (slide: any) => {
		console.log(slide)

		if (slide?.has_buttom) {
			// If has onClick, return function
			if (slide?.onClick) {
				return slide?.onClick();
			}

			// If has link, return link
			window.open(slide?.link);
			return;
		}

		// If has searchQuery, return search
		if (slide?.search_query) {
			return navigate(`/resultados?${slide.search_query}`);
		}

		return () => { };
	}

	// Render image for desktop or mobile
	const RenderImage = ({ slide }) => (
		<SlideImage
			src={isMobile ? slide.background_movile : slide.background}
			alt={slide.name}
		/>
	);

	//Render slide content
	const _renderSlides = (slides) => {
		if (!slides.length) return <Slide key="0" />;

		return slides.map((slide) => (
			<Slide
				key={slide.id}
				onClick={() => getBannerOnClick(slide)}
			>
				{/* Banner image */}
				<RenderImage slide={slide} />

				{(slide?.title || slide?.description) && (
					<SlideContent>
						{slide?.title && <Heading>{slide.title}</Heading>}

						{slide?.description && (
							// String to html
							<Description
								dangerouslySetInnerHTML={{
									__html: slide.description,
								}}
							/>
						)}
					</SlideContent>
				)}
			</Slide>
		))
	};

	// On mount
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		bannerFunction();
		fetchConfig();
	}, [user]);

	return (
		<Slider {...settings} className="banner">

			{
				// If user is not logged in, render static banners
				!user && _renderSlides(staticBanners)
			}

			{_renderSlides(banners)}
		</Slider>
	);
};

export default FullwidthCarousel;
