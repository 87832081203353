import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosPromise,
	AxiosRequestHeaders,
} from 'axios';
//@ts-ignore
import { Service } from 'axios-middleware';
// import { actions } from "../store/modules/session";
import { Store } from 'redux';

interface RequestPayload {
	data: unknown;
	err?: string;
	errmsg?: string;
}

let localStore: any = null;

export default class API {
	baseURL: string | undefined;
	scheme: string;
	request: AxiosInstance;
	isLogin: boolean;
	constructor(
		scheme: string,
		baseURL = process.env.REACT_APP_API_URL,
		isLogin = false
	) {
		this.baseURL = baseURL;
		this.scheme = scheme;
		this.isLogin = isLogin;
		this.request = axios.create({
			baseURL: this.baseURL,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const service = new Service(this.request);
		service.register({
			async onRequest(config: AxiosRequestConfig) {
				const configFlag = { ...config };
				if (configFlag?.headers?.Authorization) {
					return configFlag;
				} else {
					const token = localStore.getState().session?.user?.token;
					if (token) {
						configFlag.headers = {
							...configFlag.headers,
							Authorization: `Bearer ${token}`,
						};
					}
				}
				return configFlag;
			},
		});
	}

	requestWrapper<AxiosResponse>(
		req: AxiosPromise<any>,
		isLogin: boolean
	): Promise<AxiosResponse> {
		return req
			.then(({ data, headers }) => {
				// console.log({ data });
				try {
					if (data.err) {
						throw Error(data.err);
					} else if (isLogin) {
						return { data, headers };
					}
					return data;
				} catch (error) {
					throw error;
				}
			})
			.catch((err) => {
				// console.log('error ====>', { err });
				if (err.response?.statusText === 'Unauthorized') {
					// localStore.dispatch(actions.signOut());
				}
				if (err.response?.data) {
					throw err.response?.data.err;
				}
				throw err;
			});
	}

	get<ParamsType, AxiosResponse>(
		path?: string,
		params?: ParamsType
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({ url, data: params }),
			this.isLogin
		);
	}

	post<ParamsType, AxiosResponse>(
		path: string,
		data?: ParamsType,
		headers?: AxiosRequestHeaders,
		cancelToken?: any
	): Promise<AxiosResponse> {
		const url = path ? `${this.scheme}/${path}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'POST',
				headers,
				cancelToken: cancelToken,
			}),
			this.isLogin
		);
	}

	put<ParamsType, AxiosResponse>(
		id: number | string,
		data?: ParamsType
	): Promise<AxiosResponse> {
		const url = id ? `${this.scheme}/${id}` : this.scheme;
		return this.requestWrapper<AxiosResponse>(
			this.request({
				url,
				data,
				method: 'PUT',
			}),
			this.isLogin
		);
	}

	delete(id: number): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, method: 'DELETE' }),
			this.isLogin
		);
	}

	show(id: number, params?: any): Promise<RequestPayload> {
		return this.requestWrapper(
			this.request({ url: `${this.scheme}/${id}`, params }),
			this.isLogin
		);
	}
}

export const getStore = (store: Store) => {
	localStore = store;
};

export const baseRequest = new API('/');
