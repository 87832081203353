export default [
  {
    id: "welcome",
    title: 'Bienvenid@',
    text: 'Aquí puedes buscar por nombre, servicio o producto',
    attachTo: { element: '#searcher', on: "bottom" as const },
    classes: "shepherd",
    scrollTo: true,
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "next",
        text: "Siguiente"
      }
    ]
  },
  {
    id: "welcome2",
    title: 'Mis Benevits',
    text: 'Aquí encontrarás tus benevits que has descubierto',
    attachTo: { element: '#navItemMisBenevits', on: "bottom" as const },
    classes: "shepherd",
    scrollTo: true,
    scrollToHandler: function (e) {
      window.scrollTo({
        // top: 400,
        behavior: 'smooth',
      });
    },
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "next",
        text: "Siguiente"
      }
    ]
  },
  {
    id: "welcome3",
    title: 'Categorías',
    text: 'Aquí podrás filtrar por categoría y encontrar más rápido los productos o servicios que buscas',
    attachTo: { element: '#categories', on: "top" as const },
    classes: "shepherd",
    scrollTo: true,
    scrollToHandler: function (e) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "next",
        text: "Siguiente"
      }
    ]
  },
  {
    id: "welcome4",
    title: 'Cuenta',
    text: 'Aquí puedes configurar tus preferencias de perfil',
    attachTo: { element: '#account', on: "bottom" as const },
    classes: "shepherd",
    scrollTo: true,
    scrollToHandler: function (e) {
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });
    },
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "next",
        text: "Siguiente"
      }
    ]
  },
  {
    id: "welcome5",
    title: 'Descubre un Benevit',
    text: 'Para descubrir un benevit, da clic en "Lo quiero"',
    attachTo: { element: '#featuredBenevits', on: "top" as const },
    classes: "shepherd",
    scrollTo: true,
    scrollToHandler: function (e) {
      window.scrollTo({
        top: 200,
        behavior: 'smooth',
      });
    },
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "complete",
        text: "Terminar"
      }
    ]
  }
];