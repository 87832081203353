import { useEffect, useState } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { convertArrayToOptions, getCategoryById, getOptionsByType, isFilterInUrl } from '../../../utils/searchFunctions';
import styles from './SearchPills.module.scss';
import { categoriesAPI } from '../../../api/modules/categories';

export const Pills = ({ isLoading, name, type, paramValues }: any) => {
  const { submitForm, isSubmitting, values } = useFormikContext();
  const params = new URLSearchParams(window.location.search);
  const [catalogue, setCatalogue] = useState<any>([]);

  const _fetchFilters = async (type) => {
    const response = await categoriesAPI.categoriesFilter(`search-${type}`);

    if (response) {
      setCatalogue(convertArrayToOptions(response?.data, type));
    }
  }

  const _renderPills = (values: any, paramVal: any, arrayHelpers: any) => paramVal.map((option: any, index: number) => {
    // const option = JSON.parse(optionString);
    const pill = getCategoryById(option, catalogue);
    const showFilter = isFilterInUrl(option, type, params);
    let filterObj: any = null;

    // Find the option in values
    values?.filters?.forEach((filter: any, index: number) => {
      const filterOption = JSON.parse(filter);

      if (filterOption.value === option && filterOption.type === type) {
        // return index;
        filterObj = {
          index,
          filterOption
        }
      }
    });

    if (!showFilter || !filterObj) return null;

    return (
      <button
        key={index}
        type="submit"
        disabled={isSubmitting || isLoading}
        className={styles.pill}
        onClick={() => {
          arrayHelpers.remove(filterObj?.index);
          submitForm();
        }}
      >

        {pill?.label || pill?.name}
        <img src='/assets/icons/cross-black.svg' width={10} height={10} alt='Borrar' />
      </button>
    )
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      _fetchFilters(type);
    }

    return () => { isMounted = false; }
  }, [type]);

  if (catalogue.lenght === 0) return null;

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <>
          {paramValues && _renderPills(values, paramValues, arrayHelpers)}
        </>
      )}
    </FieldArray>
  );

};

const SearchPills = ({ isLoading, catalogue }: any) => {
  const { values }: any = useFormikContext();
  const params = new URLSearchParams(window.location.search);
  const categoriesParams = params?.get('categories')?.split(',') || [];
  const statesParams = params?.get('states')?.split(',') || [];
  const brandsParams = params?.get('brands')?.split(',') || [];

  if (!values || !values.filters.length) return null;

  return (
    <div className={styles.pills}>
      <Pills isLoading={isLoading} name='filters' type='categories' paramValues={categoriesParams} />
      <Pills isLoading={isLoading} name='filters' type='states' paramValues={statesParams} />
      <Pills isLoading={isLoading} name='filters' type='brands' paramValues={brandsParams} />
    </div>
  );
};

export default SearchPills;