import { useState } from 'react';
import styles from './MobileMenu.module.scss';
import Searcher from '../Searcher/Searcher';
import { NavLink } from 'react-router-dom';
import Drawer from './Drawer/Drawer';

const MobileMenu = ({ setOpenRegister, setModalNewsLetter, ...props }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.top}>
          <NavLink to="/">
            <img src="/assets/brand/socio-infonavit-color.svg" alt="Socio Infonavit" className={styles.logo} />
          </NavLink>

          <button type="button" className={styles.burger} onClick={() => setOpen(true)}>
            <span />
            <span />
            <span />
          </button>
        </div>

        <div className={styles.bottom}>
          <Searcher />
        </div>
      </header>

      <Drawer open={open} setOpen={setOpen} {...{ setOpenRegister, setModalNewsLetter }} />
    </>
  );
};

export default MobileMenu;