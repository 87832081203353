import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import logoApp from '../../assets/img/icon_si.png';
import appStore from '../../assets/img/app-store.png';
import googlePlay from '../../assets/img/google-play.png';
import appGallery from '../../assets/img/huawei-app-gallery.png';
import apple from '../../assets/img/apple.png';
import android from '../../assets/img/google.png';
import huawei from '../../assets/img/huawei.png';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '10',
            display: 'flex',
            overflow: 'auto',
        },
        container: {
            width: '320px',
            height: 'auto',
            opacity: '1',
            textAlign: 'center',
            position: 'relative',
            backgroundColor: '#FFFFFF',
            borderRadius: '22px',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
        },
        containerData: {
            /* Frame 1 */
            boxSizing: 'border-box',
            /* Auto layout */
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '22px 16px',
            gap: '10px',
            margin: 'auto',
            background: '#FFFFFF',
            border: '1px solid #E7E7E7',
            borderRadius: '22px',
        },
        title: {
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: '700',
            color: '#090909',
            textAlign: 'center',
            margin: '22px 42px 0 42px'
        },
        text: {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'center',
            margin: 0
        },
        divStores: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 0px 16px',
            gap: '30px',
        },
    })
);

const PopupApp = () => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [OS, setOS] = useState<string>('');

    const closeModal = () => {
        setOpenModal(!openModal);
        localStorage.setItem('popupApp', 'true');
    };
    useEffect(() => {
        const saved = localStorage.getItem('popupApp');
        if (!saved) {
            localStorage.setItem('popupApp', 'false');
            setOpenModal(true);
        } else if (saved === 'false') {
            setOpenModal(true);
        }
        var userAgent = navigator.userAgent || navigator.vendor;
        if (/huawei/i.test(userAgent)) {
            setOS("Huawei")
        } else if (/android/i.test(userAgent)) {
            setOS("Android")
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setOS("iOS")
        } else {
            setOS("Other")
        }
    }, []);

    return (
        <>
            {openModal && (
                <Box className={classes.background}>
                    <Box className={classes.container}>
                        <div className={classes.containerData}>
                            <div>
                                <h1 className={classes.title}>Ahorra rápido y fácil</h1>
                                <p className={classes.text}>
                                    estés donde estés, con nuestra
                                    aplicación móvil disponible en
                                </p>
                            </div>
                            <div>
                                <img src={logoApp} />
                            </div>
                            {(OS === "Windows" || OS === "macOS" || OS === "Linux" || OS === "Other") &&
                                (<div className={classes.divStores}>
                                    <a href="https://apps.apple.com/mx/app/socio-infonavit-app/id6447075538" target='_blank'><img src={appStore} /></a>
                                    <a href="https://play.google.com/store/apps/details?id=mx.infonavit.socio" target='_blank'><img src={googlePlay} /></a>
                                    <a href="https://appgallery.huawei.com/app/C107975941" target='_blank'><img src={appGallery} /></a>
                                </div>)
                            }
                            {(OS === "iOS") &&
                                (
                                    <a href="https://apps.apple.com/mx/app/socio-infonavit-app/id6447075538" target='_blank'>
                                        <img src={apple} />
                                    </a>
                                )
                            }
                            {(OS === "Android") &&
                                (<div>
                                    <a href="https://play.google.com/store/apps/details?id=mx.infonavit.socio" target='_blank'>
                                        <img src={android} />
                                    </a>
                                </div>
                                )
                            }
                            {(OS === "Huawei") &&
                                (<div>
                                    <a href="https://appgallery.huawei.com/app/C107975941" target='_blank'>
                                        <img src={huawei} />
                                    </a>
                                </div>
                                )
                            }
                            <Button onClick={closeModal}>
                                Cancelar
                            </Button>
                        </div>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default PopupApp;