import styled from '@emotion/styled';

export const BannerContainer = styled.div`
  @media (max-width: 768px) {
    padding: 20px 12px;
  }
`;

export const Banner: any = styled.div`

  padding: 20px 0;

  ${({ outline }: any) => outline && `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  `}

  @media (max-width: 768px) {
    ${({ outline }: any) => !outline && `padding: 20px 12px;`}
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Logo = styled.img`
  width: 140px;
  height: 140px;
  object-fit: contain;
  object-position: center center;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;
