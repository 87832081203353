import { benevitsAPI } from '../api/modules/benevits';
import axios from 'axios';
import { REPUBLIC_STATES } from './republicStates';

export const convertUrlParamsToFormValues = (searchParams: any) => {
  const values: any = {
    filters: []
  };

  searchParams.forEach((value: string, key: any) => {
    const valuesArray = value.split(',');

    for (let val of valuesArray) {
      const filter = JSON.stringify({ type: key, value: val });
      values.filters.push(filter);
    }
  });
  return (values);
};

export const updateParam = (type: string, values: string, params: any) => {
  const filteredvalues = getValuesByType(type, values).toString();

  if (params.has(type)) {
    params.set(type, filteredvalues);
  } else {
    if (filtersCounter(type, values) > 0) {
      // If key is not "near" append to params
      if (type !== 'near') params.append(type, filteredvalues);
    }
  }

  return '?' + params.toString();
};

// Convert form values to query params
export const convertValuesToQueryParams = (values: any) => {
  const params = new URLSearchParams();

  for (let filterString of values) {
    const filter = JSON.parse(filterString);
    updateParam(filter.type, values, params);
  }


  if (filtersCounter('categories', values) <= 0) {
    params.delete('categories');
  }

  if (filtersCounter('brands', values) <= 0) {
    params.delete('brands');
  }

  if (filtersCounter('states', values) <= 0) {
    params.delete('states');
  }

  if (filtersCounter('near', values) <= 0) {
    params.delete('near');
  }

  if (filtersCounter('order', values) <= 0) {
    params.delete('order');
  }

  return '?' + params.toString();
};

export const convertValuesToApiQuery = (values: any) => {
  // Convert string to array
  const categoriesArray = values?.categories;
  const brandsArray = values?.brands;
  const statesArray = values?.states;

  const params = new URLSearchParams();
  if (categoriesArray && categoriesArray.length > 0) {
    categoriesArray.map((category) => params.append('categories[]', category));
  }

  if (brandsArray && brandsArray.length > 0) {
    brandsArray.map((brand) => params.append('brands[]', brand));
  }

  if (statesArray && statesArray.length > 0) {
    statesArray.map((state) => params.append('states[]', state));
  }

  if (params !== undefined && params !== null && params.toString().length) {
    return `?${params}`;
  }

  return '';
};

// Convert to options array (data: fetch response, name: from formik initial values)
export const convertArrayToOptions = (data: any, name: string) => {
  return data.map((item: any) => ({
    id: item?.name,
    value: item?.id,
    label: item?.name,
    name: name
  }));
};

// sort alphabetically
export const sortAlphabetically = (data: any) => data.sort((a: any, b: any) => {
  // replace accents
  const aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  const bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  if (aLabel < bLabel) {
    return -1;
  }
  if (aLabel > bLabel) {
    return 1;
  }
  return 0;
});

export const convertStatesArrayToOptions = (data: any, name: string) => {
  return data.map((item: any) => ({
    id: item?.id,
    value: item?.id,
    label: item?.name,
    name: name
  }));
};

// Set form values (values: formik values, setFieldValue: formik setFieldValue)
export const setFormValues = (values: any, setFieldValue: any) => {
  for (const [key, value] of Object.entries(values)) {
    setFieldValue(key, value);
  }
};

// Get category by id
export const getCategoryById = (id: string, categories: any) => {
  // console.log("ID", categories.find((category: any) => category.value == id))
  return categories.find((category: any) => category.value == id);
};

// Get brand by id
export const getBrandById = (id: number, brands: any) => {
  return brands.find((brand: any) => brand.id == id);
}

// Get geolocation
export const handleLocation = (user: any) => {
  let coordinates: any = null;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      // Update user location
      // if(user) benevitsAPI.location( latitude, longitude, user?.token);
      coordinates = { latitude, longitude };
    }, (error) => {
      console.log(error)
      coordinates = null;
    });

    return coordinates;
  };

  return coordinates;
};

// Get geolocation in promise
export const getPosition = (options?: any): Promise<any> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options));
};

// Handle geolocation
// Get user position and send it to endpoint and return state information
export const handleGeolocation = (user: any): Promise<any> => {

  const allFetch = async () => {
    // Get user position
    const position: any = await getPosition(user).then((res) => res).catch((err) => err);
    console.log('position', position)

    // If there is a position
    if (position?.coords) {
      // Send it to endpoint
      await benevitsAPI.location(position?.coords?.latitude, position?.coords?.longitude, user?.token).then((res) => {
        // OK response
        // console.log(res);
      }).catch((err) => {
        console.error(err)
      });

      // Get state information by position
      const stateData = await axios.post('https://xussh8lzj1.execute-api.us-east-1.amazonaws.com/default/location-reverse-geocode', {
        "lat": position?.coords?.latitude, "lon": position?.coords?.longitude
      });

      return findStateByName(stateData.data?.body?.Region, REPUBLIC_STATES);
    };

    return false;
  };

  if (user) {
    // Set state information in redux
    return new Promise((resolve, reject) => allFetch().then((res) => resolve(res)).catch((err) => reject(err)));
  }

  return new Promise((resolve, reject) => resolve("No user"));
};

export const findStateByName = (name: string, states: any) => {
  return states.find((state: any) => state.code === name);
};

export const findCategoryByName = (name: string, categories: any) => {
  return categories.find((category: any) => category.name === name);
}

export const getCategoryId = (name: string, categories: any) => {
  const category = findCategoryByName(name, categories);

  if (category) return category.id;

  return '';
};

export const shuffleArray = (array: any) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const isFilterInUrl = (id: string, type: string, params: URLSearchParams) => {
  const data = params?.get(type)?.split(',');
  if (data !== null && data !== undefined) {
    return data.find(item => item === id.toString());
  }

  return false;
};

export const filtersCounter = (type: string, values: any) => {
  // array ty any
  const options: any = [];

  values.map((value: any) => {
    const item = JSON.parse(value);
    if (item.type === type) {
      options.push(item);
    }
  });

  return options.length;

};

export const getOptionsByType = (type: string, values: any) => {
  // array ty any
  const options: any = [];

  values.map((value: any) => {
    const item = JSON.parse(value);
    if (item.type === type) {
      options.push(item);
    }
  });

  return options;

};

export const getValuesByType = (type: string, values: any) => {
  // array ty any
  const options: any = [];

  values.map((value: any) => {
    const item = JSON.parse(value);
    if (item.type === type) {
      options.push(item.value);
    }
  });

  return options;
}

export const getValuesByTypeInArray = (type: string, values: any) => {

  // array ty any
  const options: any = [];

  if (values.length > 0) {
    values.map((value: any) => {
      const item = JSON.parse(value);
      if (item.type === type) {
        options.push(item.value);
      }
    });
  }

  return options;
}


export const getFirstValueFromParams = async () => {
  const params = new URLSearchParams(window.location.search);

  // convert params keys to array
  const keysArray = Array.from(params.keys());

  if (keysArray.length && keysArray[0] === 'query') {
    // second key name in params position 0
    return keysArray[1];
  }

  // first key name in params position 0
  if (keysArray.length) {
    return keysArray[0].toString();
  }

  return null;
};