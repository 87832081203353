import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { benevitsAPI } from '../../api/modules/benevits';
import { coordsAPI } from '../../api/modules/coords';

const HandleGeo = () => {
  const [coords, setCoords] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { user } = useSelector(
    (state: RootState) => state.session
  );

  const getCoords = async (token: any) => {
    const response = await coordsAPI.getCoords(token);
    setCoords(response?.data?.message);
  };

  const handleSuccess = async (position: any) => {
    const { latitude, longitude } = position.coords;
    const token = user?.token;
    await benevitsAPI.location(latitude, longitude, token);
  };

  const handleError = (err: any) => {
    setError(err.message);
  };

  useEffect(() => {
    if (user != null) {
      getCoords(user?.token);
    }
  }, [user]);

  useEffect(() => {
    if (user != null) {
      const geo = navigator.geolocation;

      // Geo location is not working in the browser because it is not supported by the browser
      if (!geo) {
        setError('Geolocation is not supported');
        return;
      }

      // If user has not given coords ask for it
      if (!coords) {
        // Geo location is working in the browser
        geo.getCurrentPosition(
          handleSuccess,
          handleError
        );
      }
    }
  }, [user, coords]);

  return null;
};

export default HandleGeo;