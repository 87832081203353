export const emailValid = (value: string): { valid: boolean; msg: string } => {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (value === '') return { valid: false, msg: '' };
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'El correo electrónico que proporcionaste no tiene un formato válido, por favor verifícalo',
		};
	}
	return { valid: true, msg: 'Correo electrónico correcto' };
};

export const textToCapitalLetter = (text: string) => {
	return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const nssValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
	if (value === '')
		return { valid: false, msg: 'Escribe tu Número de Seguridad Social (NSS)' };
	if (value.length < 11) {
		return {
			valid: false,
			msg: 'El Número de Seguridad Social (NSS) debe tener 11 números, por favor verifícalo',
		};
	}
	if (!value.match(reg)) {
		return { valid: false, msg: 'NSS Incorrecto' };
	}
	return { valid: true, msg: 'NSS Correcto' };
};

export const isNumber = (value: string): boolean => {
	const reg = /^[0-9]+$/;
	if (value === '') return true;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const isEmpty = (prop: any) => {
	return (
		prop === false ||
		prop === null ||
		prop === undefined ||
		(prop.hasOwnProperty('length') && prop.length === 0) ||
		(prop.constructor === Object && Object.keys(prop).length === 0)
	);
};
