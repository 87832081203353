import { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { newsletterAPI } from '../../api/modules/newsletter';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import close from '../../assets/img/buttom/close.svg';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import news2 from '../../assets/img/newsletter/yaestas2.png';
import news3 from '../../assets/img/newsletter/noestas2.png';
import NewsletterConfirmYes from './NewsletterConfirmYes';
import NewsletterConfirmNo from './NewsletterConfirmNo';

const NewsletterModify = ({ modalNewsLetter, setModalNewsLetter, ...props }: any) => {
	const classes = useStyles();
	const wrapperRef = useRef(null);
	//const { user } = useSelector((state: RootState) => state.session);
	const { user, isOpenNewsletterModal } = useSelector(
		(state: RootState) => state.session
	);
	const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
	const [selectedOption, setSelectedOption] = useState<string>('');
	const [option, setOption] = useState<string>('');

	const dispatch = useDispatch();
	let textNewsletter: string =
		'¡Suscríbete a nuestro newsletter!';

	const closeFunction = () => {
		setModalNewsLetter(!modalNewsLetter);
		setOption(selectedOption);
		/*if (selectedOption === 'yes') {
			setOption('yes');
		} else setOption('no');*/
	};

	const closeAction = () => {
		setModalNewsLetter(!modalNewsLetter);
	};

	const answer = async () => {
		await newsletterAPI.updateMemberNewsletterSuscription(
			user?.id,
			selectedOption === 'yes',
			token
		).then((data) => {
			const userNew = data;
			console.log("DATA", data.data)
			dispatch(actions.setUser({
				...userNew.data,
				email: user?.email,
				role: user?.role,
				token: user?.token,
				sign_in_count: user?.sign_in_count,
			}));
		}).catch((error) => {
			console.log(error);
		});

		// const { data } = res;
		// dispatch(
		// 	actions.setUser({
		// 		...data,
		// 		token: user?.token,
		// 	})
		// );
	};

	useEffect(() => {
		if (user?.subscribed_to_newsletter) {
			setSelectedOption('yes');
		} else {
			setSelectedOption('no');
		}
		function handleEscapeKey(event: KeyboardEvent) {
			if (event.code === 'Escape') {
				setModalNewsLetter(!modalNewsLetter);
			}
		}
		document.addEventListener('keydown', handleEscapeKey);
		return () => document.removeEventListener('keydown', handleEscapeKey);
	}, []);

	useEffect(() => {
		dispatch(actions.setIsOpenNewsletter());
		//alert(isOpenNewsletterModal);
	}, [isOpenNewsletterModal]);

	function useOutsideClick(ref: any) {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				if (ref.current && !ref.current.contains(event.target)) {
					setModalNewsLetter(!modalNewsLetter);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	}
	useOutsideClick(wrapperRef);

	return (
		<>
			{/*closeModal && (
				<Box className={classes.background}>
					<Box
						className={`${classes.container} ${classes.containerMdf}`}
						ref={wrapperRef}
					>
						<img
							src={close}
							className={classes.imgClose}
							onClick={() => {
								setCloseModal(!closeModal);
							}}
						/>
						<div className={classes.containerData}>
							<div className={classes.divText}>
								<h1 className={classes.title}>¡Hola!</h1>
								<img src={news4} className={classes.imageModificar} />
								<p className={`${classes.text} ${classes.textMdf}`}>
									¡Infonavit Fácil está aquí para resolver tus dudas!
									Cambia tu suscripción al newsletter cuando quieras.
								</p>
							</div>
							<div
								className={`${classes.divButton} ${classes.divButtonMdf}`}
							>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										onClick={() => setSelectedOption('yes')}
										className={`${
											selectedOption === 'yes'
												? classes.contained
												: classes.outlined
										}  ${classes.buttonContained}`}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#000000',
										}}
									>
										Me quiero suscribir al newsletter
									</span>
								</div>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										onClick={() => setSelectedOption('no')}
										className={`${
											selectedOption === 'no'
												? classes.contained
												: classes.outlined
										}  ${classes.buttonContained}`}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#414141',
										}}
									>
										Por el momento no deseo suscribirme
									</span>
								</div>
							</div>
							<Button
								onClick={() => {
									answer();
									closeModalFunction();
								}}
								className={`${classes.button} ${classes.buttonMdf}`}
								variant="contained"
								disabled={selectedOption == ''}
								style={{ borderRadius: '14px' }}
							>
								<span className={classes.span}>Siguiente</span>
							</Button>
						</div>
					</Box>
				</Box>
							)*/}


			{user?.subscribed_to_newsletter && modalNewsLetter && (
				<Box className={classes.background}>
					<Box className={`${classes.container} ${classes.containerYes}`}>
						<img
							src={close}
							className={classes.imgClose}
							onClick={closeAction}
						/>
						<div className={classes.containerData}>
							<div className={classes.divText}>
								<h1 className={`${classes.titleYes}`}>
									¡Ya estás registrado!
								</h1>
								<img src={news2} className={classes.imageNoDos} />
								<p className={`${classes.text} ${classes.textYes}`}>
									¿Quieres cancelar tu suscripción?
								</p>
							</div>
							<div
								className={`${classes.divButton} ${classes.divButtonMdf}`}
							>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										className={`${selectedOption === 'yes'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
										onClick={() => setSelectedOption('yes')}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#000000',
										}}
									>
										No
									</span>
								</div>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										className={`${selectedOption === 'no'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
										onClick={() => setSelectedOption('no')}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#414141',
										}}
									>
										Sí
									</span>
								</div>
							</div>

							<button
								onClick={() => {
									answer();
									closeFunction();
								}}
								className={`${classes.button} ${classes.buttonYes}`}
								style={{ borderRadius: '14px' }}
							>
								<span className={classes.span}>Aceptar</span>
							</button>
						</div>
					</Box>
				</Box>
			)}

			{user?.subscribed_to_newsletter === false && modalNewsLetter && (
				<Box className={`${classes.background} `}>
					<Box className={`${classes.container} ${classes.containerYes}`}>
						<img
							src={close}
							className={classes.imgClose}
							onClick={closeAction}
						/>
						<div className={classes.containerData}>
							<div className={classes.divText}>
								<h1 className={`${classes.titleNo}`}>
									¡No estás registrado!
								</h1>
								<img src={news3} className={classes.imageNoDos} />
								<p className={`${classes.text} ${classes.textNo}`}>
									{textNewsletter}
								</p>
							</div>
							<div
								className={`${classes.divButton} ${classes.divButtonMdf}`}
							>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										className={`${selectedOption === 'yes'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
										onClick={() => setSelectedOption('yes')}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#000000',
										}}
									>
										Quiero suscribirme
									</span>
								</div>
								<div
									className={`${classes.selection} ${classes.selectionMdf}`}
								>
									<button
										className={`${selectedOption === 'no'
											? classes.contained
											: classes.outlined
											}  ${classes.buttonContained}`}
										onClick={() => setSelectedOption('no')}
									/>

									<span
										style={{
											textTransform: 'none',
											fontWeight: 500,
											fontSize: 16,
											color: '#414141',
										}}
									>
										Quiero quedarme sin suscribir
									</span>
								</div>
							</div>
							<button
								onClick={() => {
									answer();
									closeFunction();
								}}
								className={classes.button}
								style={{ borderRadius: '14px' }}
							>
								<span className={classes.span}>Aceptar</span>
							</button>
						</div>
					</Box>
				</Box>
			)}
			{option === 'no' && <NewsletterConfirmYes />}
			{option === 'yes' && <NewsletterConfirmNo />}
		</>
	);
};

export default NewsletterModify;

