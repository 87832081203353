export const MENU_OPTIONS = {
  header: [
    {
      id: 'profile',
      url: '/mi-perfil',
      name: 'Mi perfil',
      type: 'link',
      private: true
    },

  ],
  body: [
    {
      id: 'home',
      url: '/',
      name: 'Inicio',
      icon: '/assets/icons/home.svg',
      type: 'link',
    },
    {
      id: 'categories',
      url: '/',
      name: 'Categorías',
      type: 'dropdown',
      options: 'categories'
    },
    {
      id: 'brands',
      url: '/marcas',
      name: 'Marcas',
      type: 'link',
    },
    {
      id: 'flash',
      url: '/resultados?categories=9',
      name: 'Benevits relámpago',
      type: 'link',
    },
    {
      id: 'misBenevits',
      url: '/mis-benevits',
      name: 'Mis Benevits',
      type: 'link',
      private: true
    },
  ]
}