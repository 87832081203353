// Dependencies
import React from 'react';
// Components
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from './router';
import store from './store';

// Material Components
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import es from 'date-fns/locale/es';

// Assets
import theme from './config/theme';
import { getStore } from './api';
import ScrollTop from './ScrollTop'

getStore(store.store);

function App(): React.ReactElement {
	return (
		<Provider store={store.store}>
			<PersistGate loading={null} persistor={store.persistor}>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
							<ScrollTop />
							<Router />
						</LocalizationProvider>
					</ThemeProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}

export default App;
