import { useState, useEffect, useRef, Fragment } from 'react';
import FullwidthCarousel from '../../components/FullWidthCarousel';
import Benevits from '../../components/Benevits';
import { benevitsAPI } from '../../api/modules/benevits';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Newsletter from '../../components/Newsletter/Newsletter';
import ArticlesThumbnails from '../../components/ArticlesThumbnails';
// @ts-ignore

import NewsletterConfirmYes from '../../components/Newsletter/NewsletterConfirmYes';
import NewsletterConfirmNo from '../../components/Newsletter/NewsletterConfirmNo';

import Tour from '../../components/Tour/tour';
import PopupApp from '../../components/PopupApp';

import 'bootstrap/dist/css/bootstrap.min.css';
import Categories from '../../components/Categories/Categories';
import FeaturedBenevits from '../../components/FeaturedBenevits/FeaturedBenevits';
import { getBrowserType } from '../../utils/regexFunctions';
import FirebaseNotification from '../../components/FirebaseNotification/FirebaseNotification';

type benevit = {
	benevit: object[];
};

const Home = () => {
	const [unOrLocked, setUnOrlocked] = useState<Array<benevit>>([]);
	const { user, isOpenNewsletterModal } = useSelector(
		(state: RootState) => state.session
	);
	const [isLoadingBenevits, setIsLoadingBenevits] = useState<boolean>(false);
	const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
	const subscribedToNewsletter: boolean | undefined = user?.subscribed_to_newsletter;
	const tour: boolean | undefined = user?.tour;
	const searchRefBus = useRef<HTMLHeadingElement>(null);
	const [openTour, setOpenTour] = useState<boolean>(false);
	const [openPopupApp, setopenPopupApp] = useState<boolean>(false);
	const [browser, setBrowser] = useState<string>("Unknown browser");
	const searchParams = new URLSearchParams(window.location.search);
	const [hasCoors, setHasCoors] = useState<boolean>(false);

	const getBenevitWithStatus = (
		benevitArr: object[],
		unlocked: boolean | undefined
	) => {
		let newBenevitArr: any = [];
		benevitArr.map((benevitObj: any) => {
			newBenevitArr.push({ ...benevitObj, unlocked });
		});
		return newBenevitArr;
	};
	const getBenevits = async () => {
		setUnOrlocked([]);
		if (user === null) {
			setIsLoadingBenevits(true);
			const data = await benevitsAPI.benevitsNoAuth();
			const lockedNoUser = data.locked;
			lockedNoUser.sort((a, b) => b.id - a.id);
			let allBenevitsArray: any = [...getBenevitWithStatus(lockedNoUser, false)];
			setUnOrlocked(allBenevitsArray);
			setIsLoadingBenevits(false);
		} else {
			setIsLoadingBenevits(true);
			const res = await benevitsAPI.getAllBenevits(token);
			const locked = await res?.data?.locked;
			const unLocked = await res?.data?.unlocked;

			let lockedBenevitsArray: any = [
				...getBenevitWithStatus(locked, false),
			];
			lockedBenevitsArray.sort((a, b) => b.id - a.id);
			let unlockedBenevitsArray: any = [
				...getBenevitWithStatus(unLocked, true),
			];
			unlockedBenevitsArray.sort((a, b) => b.id - a.id);
			let allBenevits: any = lockedBenevitsArray.concat(unlockedBenevitsArray);
			let index = 0;
			allBenevits.map((benevitObj: any) => {
				if (benevitObj.id === 1000) {
					index = allBenevits.indexOf(benevitObj);
					allBenevits.splice(index, 1);
					allBenevits.splice(0, 0, benevitObj);
				}
			});
			setUnOrlocked(allBenevits);
			setIsLoadingBenevits(false);
		}
	};

	// Get browser
	useEffect(() => {
		setBrowser(getBrowserType());
	}, [])

	// Get benevits
	useEffect(() => {
		getBenevits();
	}, [user]);

	// Handle open tour
	useEffect(() => {
		if (user !== null) {
			if (tour == null && subscribedToNewsletter != null && isOpenNewsletterModal == false) {
				setOpenTour(true);
			}

			if (searchParams.get('tour') === 'true') {
				setOpenTour(true);
			}
		}
	}, [user, isOpenNewsletterModal, searchParams.get('tour')]);

	useEffect(() => {
		if (user != null && tour != null && subscribedToNewsletter != null && isOpenNewsletterModal == false) {
			const saved = localStorage.getItem('popupApp');
			if (saved === null || saved === 'false') {
				setopenPopupApp(true);
			}
		}
	}, [user, isOpenNewsletterModal]);

	return (
		<>
			<div className='container'>
				<div className='row pt-4 mb-4'>
					<div className='col-12'>
						<FullwidthCarousel />
					</div>
				</div>

				<div className='row mb-5'>
					<div className='col-12'>
						<Categories />
					</div>
				</div>

				<FeaturedBenevits />

				<div className='row mb-5'>
					<div className='col-12'>
						<ArticlesThumbnails />
						{/* <SocialIcons isCard /> */}
					</div>
				</div>

				<div className='row mb-5'>
					<div className='col-12'>
						<Benevits
							title="Benevits populares"
							benevits={unOrLocked}
							isLoadingBenevits={isLoadingBenevits}
							limit={8}
						/>
					</div>
				</div>
			</div>

			{user !== null &&
				subscribedToNewsletter === null && <Newsletter />}
			{user !== null &&
				subscribedToNewsletter === false &&
				isOpenNewsletterModal && <NewsletterConfirmYes />}
			{user !== null &&
				subscribedToNewsletter &&
				isOpenNewsletterModal && <NewsletterConfirmNo />}

			<div ref={searchRefBus} />

			{openTour && <Tour isOpen={openTour} setIsOpen={setOpenTour} setopenPopupApp={setopenPopupApp} />}
			{openPopupApp && <PopupApp />}

			{/* Notification */}
			{(browser !== 'Unknown Browser' && browser !== 'Facebook Browser' && token) && <FirebaseNotification />}
		</>
	);
};

export default Home;
