import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

type Props = {
	margin?: string | number;
	fontFamily: string;
	fontSize: string | number;
	fontWeight: string | number;
  marginBottom?: string | number;
  width?: string | number;
  color?: string;
	textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
		text: {
			margin: ({ margin }) => margin,
			padding: 0,
			fontFamily: ({ fontFamily }) => fontFamily,
			fontSize: ({ fontSize }) => fontSize,
			fontWeight: ({ fontWeight }) => fontWeight,
			marginBottom: ({ marginBottom }) => marginBottom,
			width: ({ width }) => width,
			color: ({ color }) => color,
			textAlign: ({ textAlign }) => textAlign,
		}
  })
);
