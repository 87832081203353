import { useRef, useEffect, ChangeEvent, useState } from 'react';
import { useStyles } from './styles';
import { emailValid, isNumber, nssValid } from '../../utils/expressions';
import { Alert, Link, AlertColor, Dialog, TextField } from '@mui/material';
import { authAPI } from '../../api/modules/auth';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import { IUser } from '../../interfaces/user';
import FullWidthLoader from '../FullWidthLoader';
import JSEncrypt from 'jsencrypt';
import close from '../../assets/img/buttom/close.svg';
import NoCredential from '../NoCredential';

interface PropsFunction {
	openRegister: boolean;
	setOpenRegister: React.Dispatch<React.SetStateAction<boolean>>;
	openLogin: boolean;
	setOpenLogin: React.Dispatch<React.SetStateAction<boolean>>;
	email?: string;
}
interface IAlert {
	show: boolean;
	message: string;
	severity: AlertColor;
}
interface ICredentials {
	email: string;
	password: string;
}

const Register = ({
	openRegister,
	setOpenRegister,
	openLogin,
	setOpenLogin,
	email,
}: PropsFunction) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const wrapperRef = useRef(null);
	const [loader, setLoader] = useState(false);
	const [credentials, setCredentials] = useState<ICredentials>({
		email: '',
		password: '',
	});
	const [emailError, setEmailError] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		show: false,
		severity: 'success',
	});
	const [openCred, setOpenCred] = useState<boolean>(false);

	useEffect(() => {
		if (email) {
			let validate = { valid: false, msg: '' };
			// if (isNumber(email)) {
			// 	validate = nssValid(email);
			// } else {
			// 	validate = emailValid(email);
			// }
			validate = nssValid(email);
			setEmailError(validate);
			setCredentials({ ...credentials, email: email });
		}
	}, [email]);

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		if (name === 'email') {
			let validate = { valid: false, msg: '' };
			// if (isNumber(value)) {
			// 	validate = nssValid(value);
			// } else {
			// 	validate = emailValid(value);
			// }
			validate = nssValid(value);
			setEmailError(validate);
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const handleKeypress = (e: any) => {
		//it triggers by pressing the enter key
		if (e.key === 'Enter') {
			handleLogin();
		}
	};

	const handleLogin = async (): Promise<void> => {
		if (!emailError.valid) {
			setAlert({
				show: true,
				message: 'Introduzca NSS válida.',
				severity: 'warning',
			});
			return;
		} else {
			if (credentials.password.length < 1) {
				setAlert({
					show: true,
					message: 'Introduzca una contraseña válida.',
					severity: 'warning',
				});
				return;
			}

			setLoader(true);
			let encrypt: JSEncrypt = new JSEncrypt();
			encrypt.setPublicKey(
				'-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuIGVY6DVBZ/X4rWIOC5B\nkwXupvlRDZibogBmdkoER0Z3WX8PtyWcpf09fcvjaBw6Xwcw73E6uQMWMbSYR/Q8\n/6lk7TjQ7bDBnJ5M2ZI3cXhVEth2sGPfdrTWwiDjmyTOCtUUrs7DkC1vwV/uWQNo\n/Ed2wTdY6VKk8Dnkg4yZwqfzwFzJ82dh8zh0l08UHP+35B5SqDkbi4x/xCf7Qgp4\ng7omgBLfxqsTAllWAs2Ra+1jn3xzr4gOdbNZpXuoCRfrcrE/EcXbTxaPqArXSzff\nVZqjR5ulv5o5dRPT4vu7f2RKAhheXfWjQ3fzmlrckBfQf2EC3aBUu4mZmnzMIYJt\nMQIDAQAB\n-----END PUBLIC KEY-----\n'
			);
			const base64_credentials = encrypt.encrypt(
				credentials.email + ':' + credentials.password
			) as string;

			try {
				authAPI
					.post<
						{ credentials: string },
						{
							data: { member: IUser; email: string; role: string };
							headers: { authorization: string };
						}
					>('', {
						credentials: base64_credentials,
					})
					.then((res) => {
						if (res.headers.authorization) {
							const { data, headers } = res;
							dispatch(
								actions.setUser({
									...data.member,
									email: data.email,
									role: data.role,
									token: headers.authorization,
								})
							);
							setOpenRegister(!openRegister);
						} else {
							// setAlert({
							// 	show: true,
							// 	message: 'El NSS o contraseña son incorrectos.',
							// 	severity: 'warning',
							// });
							setOpenCred(true);
						}
					})
					.catch((error) => {
						if (error?.description)
							setAlert({
								show: true,
								message:
									error?.description ||
									'Usuario o contraseña incorrectos',
								severity: 'error',
							});
						else setOpenCred(true);
					})
					.finally(() => {
						setLoader(false);
					});
			} catch (error) {
				setLoader(false);
				// setAlert({
				// 	show: true,
				// 	message: 'El NSS o contraseña son incorrectos.',
				// 	severity: 'warning',
				// });
				setOpenCred(true);
			}
		}
	};
	return (
		<>
			<FullWidthLoader open={loader} />
			<NoCredential open={openCred} setOpen={setOpenCred} />
			<Dialog
				sx={{ '& .MuiDialog-paper': { borderRadius: '22px', width: 560 } }}
				open={openRegister}
				onClose={() => setOpenRegister(false)}
				maxWidth={'sm'}
				scroll={'body'}
			>
				<div className={classes.container} ref={wrapperRef}>
					<img
						src={close}
						alt="cerrar"
						onClick={() => setOpenRegister(!openRegister)}
						className={classes.closeButton}
					/>
					<div className={classes.div}>
						<div className={classes.divText}>
							<p className={classes.title}>
								¡Ahora es más fácil registrarse!{' '}
							</p>

							<p className={classes.text}>
								Si aún no tienes cuenta en Socio Infonavit, sólo necesitas
								ingresar tu NSS y la contraseña que usas para acceder a Mi
								Cuenta Infonavit. ¡Y listo, ya estás registrado!
							</p>
						</div>
						<Link
							target="_blank"
							href="https://portalmx.infonavit.org.mx/wps/wcm/connect/cbbe37bb-58e8-4795-9fab-6cc0de7fb26a/API_SI_aprobado.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=o3ywo6C"
							style={{ textDecorationColor: '#8E8E8E' }}
						>
							<p className={classes.aviso}>
								Consulta nuestro Aviso de Privacidad
							</p>
						</Link>
						<div className={classes.containerInput}>
							<TextField
								id="email"
								label="NSS"
								variant="outlined"
								name="email"
								type="text"
								onChange={handleInputChange}
								className={classes.input}
								margin="normal"
								onKeyPress={handleKeypress}
								value={credentials.email}
								inputProps={{ maxLength: 11 }}
								// error={!emailError.valid}
								// helperText={!emailError.valid ? emailError.msg : ''}
							/>
							<TextField
								id="password"
								label="Contraseña"
								variant="outlined"
								name="password"
								type="password"
								className={classes.input}
								onChange={handleInputChange}
								margin="normal"
								value={credentials.password}
								onKeyPress={handleKeypress}
							/>
							{/* <div className={classes.divInput}>
								<input
									name="email"
									type="email"
									className={classes.input}
									onChange={handleInputChange}
									required
									onKeyPress={handleKeypress}
									value={credentials.email}
								/>
								<label className={classes.label}>
									<span className={classes.name}>
										NSS
									</span>
								</label>
							</div>

							<div className={classes.divInput}>
								<input
									name="password"
									type="password"
									className={classes.input}
									onChange={handleInputChange}
									required
									onKeyPress={handleKeypress}
									value={credentials.password}
								/>
								<label className={classes.label}>
									<span className={classes.name}>Contraseña</span>
								</label>
							</div> */}
						</div>
						{alert.show && (
							<Alert severity={alert.severity}>{alert.message}</Alert>
						)}

						<button className={classes.button} onClick={handleLogin}>
							Registrarse
						</button>

						<p className={`${classes.text} ${classes.cuenta}`}>
							Si ya tienes cuenta en Socio Infonavit,
						</p>
						<p
							className={`${classes.text} ${classes.accede}`}
							onClick={() => {
								return (
									setOpenLogin(!openLogin),
									setOpenRegister(!openRegister)
								);
							}}
						>
							accede normalmente.
						</p>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default Register;
